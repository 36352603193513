<script>
import DatafileResultService from "@/api/services/datafileResult.service.js";
import ResponseStatus from "@/shared/enums/responseStatus";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  components: {
    VueTagsInput
  },
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileId: String,
    selectedDataRecord: Object,
    dataPointIndentifier: String,
    questionTitle: String,
    openFromRespondentModal: Boolean
  },
  data() {
    return {
      /****** Data Varaibles *****/
      suggestedScore: null,
      commentOnSuggestedScore: null,
      reasonForSuggestedScore: null,
      reasonsForScores: {
        reason1: "The answer does not match with the sense of the question",
        reason2: "Swear word not detected",
        reason3: "Gibberish not detected",
        reason4: "Valid answer with a too low score",
        reason5: "Invalid answer with a too high score",
        reason6: "Other (Comment)"
      },
      addDictionaryWords: [],
      dictionaryLanguage: null,
      languagesForDictionary: [
        {
          value: "de",
          name: "datafileResultOESTab.suggestScore.language.option1"
        },
        {
          value: "en",
          name: "datafileResultOESTab.suggestScore.language.option2"
        },
        {
          value: "default",
          name: "datafileResultOESTab.suggestScore.language.option3"
        }
      ],
      /******* Operational Variables ******/
      suggestScoreSuccessCountdown: 0,
      suggestScoreFailedCountdown: 0,
      suggestScoreValidationErrorCountdown: 0,
      dismissSecs: 3,
      isLoading: false,
      expandDictionary: false,
      vue_tag: "",
      isDisplayInfoModal: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async mounted() {
    if (this.selectedDataRecord.suggestedBy) {
      this.suggestedScore = this.selectedDataRecord.qualityScore;
      if (this.selectedDataRecord.comment === this.reasonsForScores.reason1) {
        this.reasonForSuggestedScore = this.reasonsForScores.reason1;
      } else if (
        this.selectedDataRecord.comment === this.reasonsForScores.reason2
      ) {
        this.reasonForSuggestedScore = this.reasonsForScores.reason2;
      } else if (
        this.selectedDataRecord.comment === this.reasonsForScores.reason3
      ) {
        this.reasonForSuggestedScore = this.reasonsForScores.reason3;
      } else if (
        this.selectedDataRecord.comment === this.reasonsForScores.reason4
      ) {
        this.reasonForSuggestedScore = this.reasonsForScores.reason4;
      } else if (
        this.selectedDataRecord.comment === this.reasonsForScores.reason5
      ) {
        this.reasonForSuggestedScore = this.reasonsForScores.reason5;
      } else {
        this.reasonForSuggestedScore = this.reasonsForScores.reason6;
        this.commentOnSuggestedScore = this.selectedDataRecord.comment;
      }
    }
  },
  methods: {
    suggestScoreSuccessCountdownChanged(dismissCountDown) {
      this.suggestScoreSuccessCountdown = dismissCountDown;
    },
    suggestScoreFailedCountdownChanged(dismissCountDown) {
      this.suggestScoreFailedCountdown = dismissCountDown;
    },
    suggestScoreValidationErrorCountdownChanged(dismissCountDown) {
      this.suggestScoreValidationErrorCountdown = dismissCountDown;
    },
    formatScore(value) {
      if (value > 100) return 100;
      if (value < 0) return 0;
      return value;
    },
    clickClose() {
      if (this.openFromRespondentModal) {
        this.$emit(
          "suggestScoreFromRespondent",
          this.selectedDataRecord.dataRecordId
        );
      } else {
        this.$emit("close");
      }
    },
    async clickSuggestScore() {
      this.isLoading = true;

      if (
        this.suggestedScore === 0 ||
        this.suggestedScore ||
        this.addDictionaryWords.length > 0
      ) {
        try {
          let resontoPass =
            this.reasonForSuggestedScore === this.reasonsForScores.reason7
              ? this.commentOnSuggestedScore
              : this.reasonForSuggestedScore;
          const wordsToSend = [];
          for (const word of this.addDictionaryWords) {
            wordsToSend.push(word.text);
          }

          let dictionary =
            this.dictionaryLanguage === "default"
              ? null
              : this.dictionaryLanguage;

          const response = await DatafileResultService.sendOESFeedback(
            this.datafileId,
            this.selectedDataRecord.dataRecordId,
            this.dataPointIndentifier,
            this.suggestedScore,
            resontoPass,
            wordsToSend,
            dictionary
          );

          if (response === ResponseStatus.SUCCESS) {
            this.isLoading = false;
            this.suggestScoreSuccessCountdown = this.dismissSecs;
            if (this.openFromRespondentModal) {
              this.$emit(
                "suggestScoreFromRespondent",
                this.selectedDataRecord.dataRecordId
              );
            } else {
              this.$emit("suggestAndClose");
            }
          } else {
            this.isLoading = false;
            this.suggestScoreFailedCountdown = this.dismissSecs;
          }
        } catch (e) {
          this.isLoading = false;
          this.suggestScoreFailedCountdown = this.dismissSecs;
        }
      } else {
        this.isLoading = false;
        this.suggestScoreValidationErrorCountdown = this.dismissSecs;
      }
    },
    getTranslationsByKey(key) {
      return this.$t(key);
    },
    languageOptions({ name }) {
      // used for mutislect option display with translations
      return this.$t(name);
    }
  }
};
</script>

<template>
  <b-modal v-model="showModal" centered hide-footer hide-header size="xl">
    <!-- Header -->
    <div class="row">
      <div class="col-md-12">
        <h6>
          <i class="bx bx-highlight mr-2"></i
          >{{ $t("datafileResultOESTab.suggestScore.header") }}
        </h6>
        <p class="mt-3 mb-4">
          {{ $t("datafileResultOESTab.suggestScore.description") }}
        </p>
      </div>
    </div>

    <!-- Alert Scores-->
    <div class="row">
      <div class="col-md-12">
        <b-alert
          :show="suggestScoreSuccessCountdown"
          dismissible
          @dismissed="suggestScoreSuccessCountdown = 0"
          @dismiss-count-down="suggestScoreSuccessCountdownChanged"
          class="col-md-12"
          variant="success"
          >{{ $t("datafileResultOESTab.suggestScore.success") }}</b-alert
        >

        <b-alert
          :show="suggestScoreFailedCountdown"
          dismissible
          @dismissed="suggestScoreFailedCountdown = 0"
          @dismiss-count-down="suggestScoreFailedCountdownChanged"
          class="col-md-12"
          variant="danger"
          >{{ $t("datafileResultOESTab.suggestScore.failure") }}
        </b-alert>

        <b-alert
          :show="suggestScoreValidationErrorCountdown"
          dismissible
          @dismissed="suggestScoreValidationErrorCountdown = 0"
          @dismiss-count-down="suggestScoreValidationErrorCountdownChanged"
          class="col-md-12"
          variant="danger"
          >{{ $t("datafileResultOESTab.suggestScore.requiredErrorMsg") }}
        </b-alert>
      </div>
    </div>

    <!-- Information -->
    <div class="row">
      <div class="col-md-12 mb-2">
        <b-badge class="font-size-12" variant="primary">
          {{ $t("datafileResultOESTab.suggestScore.questionTitle") }}:
        </b-badge>
        {{ this.questionTitle }}
      </div>
      <div class="col-md-12 mb-2">
        <b-badge class="font-size-12" variant="success">
          {{ $t("datafileResultOESTab.suggestScore.answer") }}:
        </b-badge>
        {{ this.selectedDataRecord.answerFromRespondent }}
      </div>
      <div class="col-md-12 mb-2">
        <b-badge class="font-size-12" variant="warning">
          Score:
        </b-badge>
        {{ this.selectedDataRecord.qualityScore }}
      </div>
      <div class="col-md-12" v-if="this.selectedDataRecord.suggestedBy">
        <label>
          {{ $t("datafileResultOESTab.suggestScore.suggestedBy") }}
        </label>
        {{ this.selectedDataRecord.suggestedBy }}
      </div>
    </div>

    <!-- Form -->
    <div v-if="!this.isLoading">
      <hr />

      <!-- Score and Reson -->
      <div class="row">
        <!-- Score -->
        <div class="col-md-4">
          <div class="row align-items-center">
            <div class="col-md-12">
              <label>
                {{ $t("datafileResultOESTab.suggestScore.score.label") }}
              </label>
            </div>
            <div class="col-md-12">
              <b-form-input
                v-model.number="suggestedScore"
                type="number"
                min="0"
                max="100"
                :placeholder="
                  $t('datafileResultOESTab.suggestScore.score.placeholder')
                "
                :formatter="formatScore"
              >
              </b-form-input>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12 mb-3">
              {{ $t("datafileResultOESTab.suggestScore.scoreInfo.text") }}
            </div>
            <ul>
              <li>
                <div class="col-md-12">
                  {{ $t("datafileResultOESTab.suggestScore.scoreInfo.range1") }}
                </div>
              </li>
              <li>
                <div class="col-md-12">
                  {{ $t("datafileResultOESTab.suggestScore.scoreInfo.range2") }}
                </div>
              </li>
              <li>
                <div class="col-md-12">
                  {{ $t("datafileResultOESTab.suggestScore.scoreInfo.range3") }}
                </div>
              </li>
              <li>
                <div class="col-md-12">
                  {{ $t("datafileResultOESTab.suggestScore.scoreInfo.range4") }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-2"></div>
        <!-- Select Reason -->
        <div class="col-md-6">
          <div class="row align-items-center">
            <div class="col-md-12">
              <label>
                {{ $t("datafileResultOESTab.suggestScore.reason") }}:
              </label>
            </div>
            <!-- Radio Buttons -->
            <div>
              <!-- Reasons -->
              <div class="col-md-12">
                <b-form-radio-group v-model="reasonForSuggestedScore" stacked>
                  <b-form-radio
                    :value="this.reasonsForScores.reason1"
                    class="mb-2"
                    >{{
                      $t("datafileResultOESTab.suggestScore.reason1")
                    }}</b-form-radio
                  >
                  <b-form-radio
                    :value="this.reasonsForScores.reason2"
                    class="mb-2"
                    >{{
                      $t("datafileResultOESTab.suggestScore.reason2")
                    }}</b-form-radio
                  >
                  <b-form-radio
                    :value="this.reasonsForScores.reason3"
                    class="mb-2"
                    >{{
                      $t("datafileResultOESTab.suggestScore.reason3")
                    }}</b-form-radio
                  >
                  <b-form-radio
                    :value="this.reasonsForScores.reason4"
                    class="mb-2"
                    >{{
                      $t("datafileResultOESTab.suggestScore.reason4")
                    }}</b-form-radio
                  >
                  <b-form-radio
                    :value="this.reasonsForScores.reason5"
                    class="mb-2"
                    >{{
                      $t("datafileResultOESTab.suggestScore.reason5")
                    }}</b-form-radio
                  >
                  <b-form-radio
                    :value="reasonsForScores.reason6"
                    class="mb-2"
                    >{{
                      $t("datafileResultOESTab.suggestScore.reasonOther")
                    }}</b-form-radio
                  >
                </b-form-radio-group>
              </div>
            </div>
            <div
              class="col-md-12 mt-3"
              v-if="
                this.reasonForSuggestedScore === this.reasonsForScores.reason6
              "
            >
              <textarea
                v-model="commentOnSuggestedScore"
                class="form-control"
                name="textarea"
                rows="3"
                :placeholder="
                  this.$t(
                    'datafileResultOESTab.suggestScore.commentPlaceholder'
                  )
                "
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <!-- Add word to dictionary -->
      <div class="row mt-4">
        <div class="col-md-6">
          <b-form-checkbox v-model="expandDictionary">
            {{ $t("datafileResultOESTab.suggestScore.expandDictionary.label") }}
          </b-form-checkbox>
          <vue-tags-input
            class="mt-2"
            v-if="this.expandDictionary"
            v-model="vue_tag"
            :tags="addDictionaryWords"
            :placeholder="
              $t(
                'datafileResultOESTab.suggestScore.expandDictionary.placeholder'
              )
            "
            @tags-changed="newTags => (addDictionaryWords = newTags)"
          />
        </div>
        <div class="col-md-4" v-if="this.expandDictionary">
          <label>
            {{ $t("datafileResultOESTab.suggestScore.language.label") }}
          </label>
          <select
            v-model="dictionaryLanguage"
            class="form-control"
            :placeholder="
              this.$t('datafileResultOESTab.suggestScore.language.placeholder')
            "
          >
            <option :value="languagesForDictionary[0].value">
              {{ this.$t(languagesForDictionary[0].name) }}
            </option>
            <option :value="languagesForDictionary[1].value">
              {{ this.$t(languagesForDictionary[1].name) }}
            </option>
            <option :value="languagesForDictionary[2].value">
              {{ this.$t(languagesForDictionary[2].name) }}
            </option>
          </select>
        </div>
      </div>

      <!-- Actions-->
      <div class="row mt-5">
        <div class="col">
          <b-button @click="clickSuggestScore()" variant="success" class="mr-2"
            ><i class="bx bx-save"></i>
            {{
              $t("datafileResultOESTab.suggestScore.action.suggest")
            }}</b-button
          >
          <b-button @click="clickClose()" variant="light"
            ><i class="bx bx-x"></i>
            {{
              $t("datafileResultOESTab.suggestScore.action.cancel")
            }}</b-button
          >
        </div>
      </div>
    </div>

    <!-- Loading -->
    <div class="row mt-4" v-if="this.isLoading">
      <div class="col-md-12 text-center">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
