var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        size: "xl",
        scrollable: "",
        title: "Scrollable Modal",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("div", { staticClass: "col-md-4" }, [
                _c("h5", [
                  _c("i", { staticClass: "bx bxs-pie-chart-alt-2 mr-2" }),
                  _vm._v(
                    _vm._s(
                      _vm.$t("datafileResultGeneral.quotaSettingsModal.title")
                    ) + " "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "col-md-8 text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: { size: "sm", variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.clickExportQuotaSetting()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "bx bx-export mr-1" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultGeneral.quotaSettingsModal.button.exportQuotaSettings"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.addNewQuotaSetting()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "bx bx-plus mr-1" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultGeneral.quotaSettingsModal.button.addNewQuotaSettings"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "light" },
                      on: {
                        click: function ($event) {
                          return _vm.clickCloseButton()
                        },
                      },
                    },
                    [_c("i", { staticClass: "bx bx-x" })]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row p-2" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-alert",
              {
                staticClass: "col-md-12",
                attrs: {
                  show: _vm.settingsExportSuccess,
                  variant: "success",
                  dismissible: "",
                },
                on: {
                  dismissed: function ($event) {
                    _vm.dismissCountDown = 0
                  },
                  "dismiss-count-down": _vm.countDownChanged,
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "datafileResultGeneral.quotaSettingsModal.alert.exportSuccess"
                    )
                  )
                ),
              ]
            ),
            _c(
              "b-alert",
              {
                staticClass: "col-md-12",
                attrs: {
                  show: _vm.cannotExportSettings,
                  variant: "warning",
                  dismissible: "",
                },
                on: {
                  dismissed: function ($event) {
                    _vm.dismissCountDown = 0
                  },
                  "dismiss-count-down": _vm.countDownChanged,
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "datafileResultGeneral.quotaSettingsModal.alert.cannotExport"
                    )
                  )
                ),
              ]
            ),
            _c(
              "b-alert",
              {
                staticClass: "col-md-12",
                attrs: {
                  show: _vm.settingsUpdateSuccess,
                  variant: "success",
                  dismissible: "",
                },
                on: {
                  dismissed: function ($event) {
                    _vm.dismissCountDown = 0
                  },
                  "dismiss-count-down": _vm.countDownChanged,
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "datafileResultGeneral.quotaSettingsModal.alert.updateSettingsSuccess"
                    )
                  )
                ),
              ]
            ),
            _c(
              "b-alert",
              {
                staticClass: "col-md-12",
                attrs: {
                  show: _vm.settingsUpdateFailed,
                  variant: "danger",
                  dismissible: "",
                },
                on: {
                  dismissed: function ($event) {
                    _vm.dismissCountDown = 0
                  },
                  "dismiss-count-down": _vm.countDownChanged,
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "datafileResultGeneral.quotaSettingsModal.alert.updateSettingsFaild"
                    )
                  )
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      !this.isLoading && this.quotaSettings.length > 0
        ? _c(
            "div",
            { staticClass: "p-2" },
            _vm._l(this.quotaSettings, function (setting, settingIndex) {
              return _c("div", { key: settingIndex, staticClass: "card" }, [
                setting.isEditable
                  ? _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c(
                              "b-alert",
                              {
                                staticClass: "col-md-12",
                                attrs: {
                                  show: _vm.validationFailed,
                                  variant: "danger",
                                  dismissible: "",
                                },
                                on: {
                                  dismissed: function ($event) {
                                    _vm.dismissCountDown = 0
                                  },
                                  "dismiss-count-down": _vm.countDownChanged,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultGeneral.quotaSettingsModal.alert.validationError"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row align-items-center" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.selectColumnLabel"
                                    )
                                  ) +
                                  ": "
                              ),
                            ]),
                            setting.originalColumnName
                              ? _c(
                                  "select",
                                  {
                                    staticClass: "form-control",
                                    staticStyle: {
                                      background: "#f5f5f5",
                                      "-webkit-appearance": "none",
                                      "-moz-appearance": "none",
                                    },
                                    attrs: {
                                      disabled: setting.originalColumnName,
                                    },
                                  },
                                  [
                                    _c("option", [
                                      _vm._v(
                                        _vm._s(setting.originalColumnName)
                                      ),
                                    ]),
                                  ]
                                )
                              : _c("multiselect", {
                                  attrs: {
                                    options: _vm.workingDatafileColumns,
                                    placeholder: _vm.$t(
                                      "uploadProcessStep2.form.general.selectColumn.placeholder"
                                    ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.selectDatafileColumn(
                                        settingIndex
                                      )
                                    },
                                  },
                                  model: {
                                    value: setting.originalColumnName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        setting,
                                        "originalColumnName",
                                        $$v
                                      )
                                    },
                                    expression: "setting.originalColumnName",
                                  },
                                }),
                          ],
                          1
                        ),
                        setting.originalColumnName
                          ? _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.renameColumnLabel"
                                        )
                                      ) +
                                      ": "
                                  ),
                                ]),
                                _c("b-form-input", {
                                  model: {
                                    value: setting.renamedColumnName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        setting,
                                        "renamedColumnName",
                                        $$v
                                      )
                                    },
                                    expression: "setting.renamedColumnName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      !setting.isGetOptions
                        ? _c(
                            "div",
                            { staticClass: "row justify-content-center mt-5" },
                            [
                              _c(
                                "div",
                                { staticClass: "col-6 text-center pt-4 pb-4" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-loader-circle bx-spin mr-2",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.fetchingOptions"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      setting.isGetOptions && setting.quotaOptions.length > 0
                        ? _c(
                            "div",
                            { staticClass: "mt-5" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-1" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.showLabel"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-4" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.originalOptionLabel"
                                          )
                                        )
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-5" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.renameOptionLabel"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.tragetLabel"
                                          )
                                        )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._l(
                                setting.quotaOptions,
                                function (option, optionIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: optionIndex,
                                      staticClass:
                                        "row mb-2 align-items-center",
                                    },
                                    [
                                      _c("div", { staticClass: "col-md-1" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: option.isEnabled,
                                              expression: "option.isEnabled",
                                            },
                                          ],
                                          attrs: {
                                            variant: "primary",
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              option.isEnabled
                                            )
                                              ? _vm._i(option.isEnabled, null) >
                                                -1
                                              : option.isEnabled,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = option.isEnabled,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      option,
                                                      "isEnabled",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      option,
                                                      "isEnabled",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  option,
                                                  "isEnabled",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-4" },
                                        [
                                          option.originalName
                                            ? _c("b-form-input", {
                                                staticStyle: {
                                                  background: "#f5f5f5",
                                                  "-webkit-appearance": "none",
                                                  "-moz-appearance": "none",
                                                },
                                                attrs: { disabled: "" },
                                                model: {
                                                  value: option.originalName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      option,
                                                      "originalName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "option.originalName",
                                                },
                                              })
                                            : _c("b-form-input", {
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.changeOptionsOriginalName(
                                                      settingIndex,
                                                      optionIndex
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: option.originalName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      option,
                                                      "originalName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "option.originalName",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-5" },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: option.name,
                                              callback: function ($$v) {
                                                _vm.$set(option, "name", $$v)
                                              },
                                              expression: "option.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-2 text-right" },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              placeholder: _vm.$t(
                                                "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.tragetPlaceholder"
                                              ),
                                            },
                                            model: {
                                              value: option.targetValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  option,
                                                  "targetValue",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "option.targetValue",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c("div", { staticClass: "row mt-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { variant: "light" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addAnotherOption(
                                              settingIndex
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "bx bx-plus mr-1",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.button.addAnotherOption"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "row mt-5" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            setting.isGetOptions &&
                            setting.quotaOptions.length > 0
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { variant: "success", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickSaveSettings(
                                          settingIndex
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "bx bx-save mr-1" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.button.save"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            setting.quotaOptions.length > 0 &&
                            setting.settingsSaveCount > 0
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { variant: "danger", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickRemoveSettings(
                                          settingIndex
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bx bx-trash mr-1",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.button.remove"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-2",
                                attrs: { variant: "light", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    setting.settingsSaveCount === 0
                                      ? _vm.clickCancelSettings(settingIndex)
                                      : _vm.changeEditStatus(settingIndex)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "bx bx-x mr-1" }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.button.cancel"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("h6", [_vm._v(_vm._s(setting.renamedColumnName))]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-6 text-right" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-2",
                                attrs: { variant: "warning", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickEditSettings(settingIndex)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "bx bxs-pencil mr-1" }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultGeneral.quotaSettingsModal.button.editSetting"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row mt-3" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("apexchart", {
                              attrs: {
                                lazy: "",
                                type: "bar",
                                height: setting.chartHeight,
                                options:
                                  _vm.quotaSettingsChartOptions.chartOptions,
                                series: [...setting.chartData],
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
              ])
            }),
            0
          )
        : _vm._e(),
      !this.isLoading && this.quotaSettings.length === 0
        ? _c("div", { staticClass: "p-2 row justify-content-center" }, [
            _c("div", { staticClass: "col-6 text-center pt-3 pb-5" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "datafileResultGeneral.quotaSettingsModal.noSettings"
                    )
                  ) +
                  " "
              ),
            ]),
          ])
        : _vm._e(),
      this.isLoading
        ? _c("div", { staticClass: "p-2 row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-3 pb-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }