<script>
import { trafficLightClassDecider } from "@/shared/util/chart-helper";
import { INVALID_SCORE } from "@/shared/util/const.js";

export default {
  props: {
    predictionScoreObject: Object,
    updateSelectedPS: Function,
    PSSelectOptions: Array,
    selectedPSDatapoint: String,
    chartDataForPScorePerformanceChart: Array,
    showPSAnswerChart: Boolean,
    psAvgPredictionChartOptions: Object,
    pScoreChart: Object
  },
  data() {
    return {
      INVALID_SCORE: null,
      trafficLightClassDecider: trafficLightClassDecider
    };
  },
  async mounted() {
    // create chart oprions
    this.INVALID_SCORE = INVALID_SCORE;
  }
};
</script>
<template>
  <div>
    <!-- PS Overview -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Not Valid Msg-->
            <div
              v-if="
                predictionScoreObject.predictionScore &&
                  predictionScoreObject.predictionScore === INVALID_SCORE
              "
              class="row mb-2"
            >
              <!-- Respondent Not Valid Msg -->
              <div class="col-md-12">
                <b-alert show variant="info">
                  <i class="mdi mdi-alert-circle-outline mr-2"></i>
                  {{ $t("dataRecordModal.general.notDataRecordMsg") }}
                </b-alert>
              </div>
            </div>

            <!-- If Pscore is Valid -->
            <div v-else class="row pb-2">
              <div class="col-md-9">
                <!-- Rank Quantile -->
                <div class="row">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.predictionScoreTab.generalSection.quantileRank"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ predictionScoreObject.rankPercentile }}
                  </div>
                </div>
                <!-- Valid Data Points -->
                <div class="row">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.predictionScoreTab.generalSection.validDataPoints"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ predictionScoreObject.validDataPoints }}
                    /
                    {{ predictionScoreObject.totalDataPoints }}
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <apexchart
                  ref="meanChart"
                  class="apex-charts apexChartData"
                  type="radialBar"
                  height="150"
                  dir="ltr"
                  :series="[predictionScoreObject.predictionScore.toFixed(3)]"
                  :options="pScoreChart.chartOptions"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PS Data Points -->
    <div
      class="row"
      v-if="
        (predictionScoreObject.predictionScore ||
          predictionScoreObject.predictionScore === 0) &&
          predictionScoreObject.predictionScore !== INVALID_SCORE
      "
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Card Topic -->
            <b-badge class="mb-3" style="font-size: 12px" variant="primary">
              {{
                $t("dataRecordModal.predictionScoreTab.dataPointSection.title")
              }}</b-badge
            >
            <div
              class="row"
              v-for="(datapoint,
              datapointIndex) in predictionScoreObject.datapoints"
              :key="datapointIndex"
            >
              <div class="col-md-4">
                <label>
                  {{
                    $t(
                      "dataRecordModal.predictionScoreTab.dataPointSection.label"
                    )
                  }}
                  {{ datapointIndex + 1 }}
                </label>
              </div>
              <div class="col-md-2">
                <span v-if="datapoint.predictionScore === INVALID_SCORE">
                  {{ $t("dataRecordModal.general.notAvailable") }}
                </span>
                <span v-else>
                  {{
                    datapoint.predictionScore.toLocaleString("de-De", {
                      minimumFractionDigits: 3
                    })
                  }}
                </span>
              </div>
              <div v-if="datapoint.predictionScore !== INVALID_SCORE">
                <!-- Traffic Light -->
                <span>
                  <i
                    class="bx bxs-circle text-primary ml-2"
                    :class="trafficLightClassDecider(datapoint.predictionScore)"
                    style="font-size: 15px"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Prediction Score Performance Chart -->
    <div
      class="row"
      v-if="predictionScoreObject.predictionScore !== INVALID_SCORE"
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Card Topic -->
            <b-badge class="mb-3" style="font-size: 12px" variant="primary">
              {{
                $t("dataRecordModal.predictionScoreTab.predictionChart.title")
              }}</b-badge
            >
            <!-- Chart -->
            <div>
              <div class="mb-3">
                <label>
                  {{
                    $t(
                      "dataRecordModal.predictionScoreTab.predictionChart.dropdownLabel"
                    )
                  }}: &nbsp;
                </label>
                <b-form-select
                  @change="updateSelectedPS"
                  class="ml-4 col-md-4"
                  v-model="selectedPSDatapoint"
                  size="sm"
                  :options="PSSelectOptions"
                >
                </b-form-select>
              </div>
              <apexchart
                ref="performanceChart2"
                class="apex-charts mt-3 mb-3"
                dir="ltr"
                type="bar"
                height="300"
                :series="[...chartDataForPScorePerformanceChart]"
                :options="{
                  ...psAvgPredictionChartOptions.chartOptions
                }"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
