export function uncleaned_answerObject() {
  this.answer = [
    {
      name: "Uncleaned Result",
      data: []
    }
  ];
}

export function cleaned_answerObject() {
  this.answer = [
    {
      name: "Uncleaned Result",
      data: []
    },
    {
      name: "Cleaned Result",
      data: []
    }
  ];
}

export function predictions_answerObject() {
  this.answer = [
    {
      name: "Projections",
      data: []
    }
  ];
}

export function quotaSettingObject() {
  this.originalColumnName = null;
  this.renamedColumnName = null;
  this.quotaOptions = [];
  this.isGetOptions = true;
  this.isEditable = true;
  this.settingsSaveCount = 0;
  this.enableOptionCount = 0;
  this.chartData = [
    {
      name: "Calculated",
      data: []
    }
  ];
  this.chartHeight = 0;
}

export function quotaOptionObject() {
  this.originalName = null;
  this.name = null;
  this.targetValue = 0;
  this.calculatedValue = 0;
  this.isEnabled = true;
}

export function quotaSettingsChartDataObject() {
  this.x = 0;
  this.y = 0;
  this.goals = [
    {
      name: "Target Value",
      value: 0,
      strokeWidth: 5,
      strokeHeight: 15,
      strokeColor: "#005181"
    }
  ];
}
