<script>
import WorksheetService from "@/api/services/worksheet.service.js";
import DataImportService from "@/api/services/dataImport.service.js";
import UserService from "@/api/services/user.service.js";
import AuthService from "@/api/services/auth.service.js";
import DatafileService from "@/api/services/datafile.service.js";
import { ERROR_CODES } from "@/shared/util/errorCodes.js";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers";
import Axios from "axios";
import s3Service from "../../../../api/services/s3.service";
import cookie from "vue-cookies";

const ENABLE_DEBUG_LOGS = false;

export default {
  props: {
    visible: Boolean,
    datafileId: String,
    oldDatafileKey: String,
    metadata: Object
  },
  data() {
    return {
      /******** Data Variables ******/
      datafileFullDataArray: [],
      totalDatarecords: 0,
      newDatarecords: 0,
      datafileKey: null,
      newDatafileName: null,
      newCredits: 0,
      isCleaned: false,
      /******** Operational Variables *******/
      hasDatafileSelected: false,
      fileReadError: false,
      isDatafileuploadProcessing: false,
      isUpdateSuccessfully: false,
      isUpdateFailed: false,
      isErrorInStructure: false,
      otherError: null,
      isDatafileMatched: false,
      isNotEnoughCredits: false,
      isLoading: false,
      cleanDatafileCheckbox: true,
      socket: null,
      uploadProgress: 0
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    },
    cleaningCheckboxPopoverContent() {
      return !this.isCleaned ? this.$t("datafileResultGeneral.updateDatafileModal.cleanCheckBoxPopoverMessage") : "";
    }
  },
  watch: {
    async visible(newVal) {
      if (newVal) {
        this.loggedInUser = await AuthService.getLoggedInUser();
        if (
          this.loggedInUser.userPreferences.isDatafileUpdating
        ) {
          this.isLoading = true;
          this.initializeWebSocket();
        } else {
          await this.checkCleaningSettings();
        }
      }
    }
  },
  methods: {
    localeFormat() {
      return "de-DE";
    },
    clickCancel() {
      this.resetDatafile();
      this.$emit("close");
    },

    formatDataforEndpoints() {
      const HEADER_ROW = this.metadata.headerRow;
      const START_ROW = this.metadata.dataRecordStartRow;
      let worksheetJSON = [];
      let headerRowData = [];
      let bodyRowData = [];

      this.datafileFullDataArray.forEach((dataRow, index) => {
        if (index + 1 === HEADER_ROW) {
          headerRowData = dataRow;
        }

        if (index + 1 >= START_ROW) {
          bodyRowData.push(dataRow);
        }
      });

      bodyRowData.forEach((bodyRow) => {
        let result = bodyRow.reduce(function (rowObject, field, index) {
          rowObject[headerRowData[index]] = field;
          return rowObject;
        }, {});
        worksheetJSON.push(result);
      });

      return {
        worksheetJSON,
        headerRowData
      };
    },
    initializeWebSocket() {
      // Create a new websocket
      try {
        const socket = new WebSocket(process.env.VUE_APP_WEB_SOCKET_SERVER_BE_PROGRESS);
        this.socket = socket;
        socket.onopen = () => {
          const jwtToken = cookie.get("accessToken");
          if (this.oldDatafileKey.indexOf(".xlsx") == -1) this.oldDatafileKey = this.oldDatafileKey + ".xlsx";
          if (ENABLE_DEBUG_LOGS) console.log("Old datafile key: " + this.oldDatafileKey);
          socket.send(jwtToken + ";" + "TRACK_PROGRESS" + ";" + this.oldDatafileKey + ";" + "START");
        }
        socket.onmessage = (event) => {
          const data = event.data;
          const socketData = String(data).split(";");
          const datafileKey = String(socketData[1]);
          if (datafileKey === this.oldDatafileKey) {
            if (socketData[0] === "PROGRESS_UPDATE") {
              const PROGRESS = Number(socketData[2]);
              if (ENABLE_DEBUG_LOGS) console.log(`OES progress update for ${datafileKey}: ${PROGRESS}%`);
              this.uploadProgress = Number(PROGRESS);
            } else if (socketData[0] === "PROGRESS_FINISHED") {
              const datafileId = socketData[3];
              if (ENABLE_DEBUG_LOGS) console.log("Upload process finished. Closing socket now.");
              socket.close();
              this.uploadDatafileSuccess = true;
              this.datafileId = datafileId;
            } else if (socketData[0] === "UPLOAD_ERROR") {
              if (ENABLE_DEBUG_LOGS) console.log("Received UPLOAD_ERROR from socket");
              const errorCode = socketData[2];
              this.isUploadDatafileError = true;
              this.otherError = getErrorMessageByErrorCode(errorCode); 
            }
          }
        };
      } catch (e) {
        if (ENABLE_DEBUG_LOGS) {
          console.log("Failed to create websocket for progress updates");
          console.log(e);
        }
      }
    },    
    async updateDatafile() {
      this.isLoading = true;
      this.initializeWebSocket();
      try {
        const resp = await WorksheetService.updateWorksheetWithNewRecords(
          this.datafileId,
          this.newCredits,
          this.cleanDatafileCheckbox
        );
        this.isLoading = false;
        if (resp.status === "SUCCESS") {
          this.isUpdateSuccessfully = true;
          this.$emit("reload");
        } else {
          this.isUpdateFailed = true;
        }
      } catch (error) {
        if (error.message === ERROR_CODES.BALANCE_NOT_ENOUGH) {
          this.isNotEnoughCredits = true;
        } else {
          this.isUpdateFailed = true;
          await this.resetDatafile(true);
        }
        this.isLoading = false;
      }
    },
    async readFile(_file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (e) => reject(e);
        reader.readAsArrayBuffer(_file);
      });
    },

    async selectFile(ev) {
      this.fileReadError = false;
      this.isErrorInStructure = false;
      this.otherError = null;
      this.isDatafileuploadProcessing = true;
      // read file and create array from the file
      const self = this;
      const file = ev.target.files[0];
      const surveyFileName = file.name;
      const fileNameString = surveyFileName.split(".");
      const fileType = fileNameString[fileNameString.length - 1];
      this.newDatafileName = surveyFileName;
      //get original file name
      const oldFileName = self.oldDatafileKey.split(".xlsx")[0];
      if (fileType === "xlsx") {
        const readerxlsx = new FileReader();
        readerxlsx.readAsBinaryString(file);
        readerxlsx.onload = async function () {
          try {
            // set filekey with the timestamp
            const tempDatafileKey = oldFileName + "_temp." + fileType;
            self.datafileKey = tempDatafileKey;
            //Step 2: Upload
            // get Pre Signed URL for XLSX File
            let XLSXPreSignedURL = await s3Service.getDatafilePreSignedURL(
              tempDatafileKey
            );
            let fileContent;
            try {
              fileContent = await self.readFile(file);
            } catch (e) {
              if (ENABLE_DEBUG_LOGS) console.error(e);
              self.fileReadError = true;
            }
            if (XLSXPreSignedURL) {
              // Upload XLSX file to S3
              try {
                await Axios.put(XLSXPreSignedURL.signedUrl, fileContent);
              } catch (error) {
                if (ENABLE_DEBUG_LOGS) console.log("Upload Error");
                self.otherError = error.message;
              }
            } else {
              if (ENABLE_DEBUG_LOGS) console.log("Presigned URL Error");
              self.fileReadError = true;
            }
            if (!self.fileReadError && !self.otherError) {
              //Step 3: Validate if there are no errors
              try {
                // call validate endpoint
                const { headerRowPayload } = await DataImportService.getDatafileHeaderRow({
                  datafileKey: self.datafileKey,
                  datafileId: self.datafileId
                });
                let result =
                  await WorksheetService.validateWorksheetWithNewRecords(
                    self.datafileId,
                    headerRowPayload
                  );
                self.hasDatafileSelected = true;
                self.isDatafileuploadProcessing = false;
                //USECASE 1: datafile matched
                self.isDatafileMatched = true;
                self.newDatarecords = result.newDatarecords;
                self.totalDatarecords = result.totalDatarecords;
                self.newCredits = result.newCredits;
              } catch (error) {
                //USECASE 2: datafile not matched
                if (
                  error.code === ERROR_CODES.DATAFILE_STRUCTURE_IS_DIFFERENT ||
                  error.code === ERROR_CODES.SHEET_NOT_FOUND
                ) {
                  self.isErrorInStructure = true;
                } else {
                  self.otherError = error.message;
                }
                self.isDatafileMatched = false;
                self.hasDatafileSelected = true;
                self.isDatafileuploadProcessing = false;
              }
            }
          } catch (e) {
            if (ENABLE_DEBUG_LOGS) console.error("error here", e);
            self.fileReadError = true;
          }
        };
      } else {
        this.isDatafileuploadProcessing = false;
        this.fileReadError = true;
      }
    },
    async resetDatafile(withError = false) {
      await s3Service.removeOriginalDatafileFromS3(this.datafileKey);

      // reset all local variables (make all varibales to initial state)
      this.hasDatafileSelected = false;
      this.isDatafileuploadProcessing = false;
      this.isUpdateSuccessfully = false;
      if (!withError) {
        this.isUpdateFailed = false;
        this.fileReadError = false;
        this.otherError = null;
      }
      this.isDatafileMatched = false;
      this.isNotEnoughCredits = false;
      this.datafileKey = null;
      this.newDatafileName = null;
      this.datafileFullDataArray = [];
      this.totalDatarecords = 0;
      this.newDatarecords = 0;
      this.newCredits = 0;
    },
    async clickMakeUpdateBackground() {
      await UserService.setSendNotification(true, "update");
      this.$emit("close");
    },
    async checkCleaningSettings() {
      const cleaningSettings = await DatafileService.getDatafileCleaningSettings(this.datafileId);
      this.cleanDatafileCheckbox = this.isCleaned = cleaningSettings.isCleaned;
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="p-2" v-if="!isLoading">
      <!-- Header and Description -->
      <div class="row mt-0 text-left">
        <div class="col-12">
          <h5 class="mb-4">
            <i class="bx bx-reset mr-2"></i>
            {{ this.$t("datafileResultGeneral.updateDatafileModal.title") }}
          </h5>
          <p class="mt-3 mb-4">
            {{
              this.$t("datafileResultGeneral.updateDatafileModal.description")
            }}
          </p>
        </div>
      </div>

      <!-- Alerts -->
      <div class="row mb-3">
        <div class="col-md-12">
          <!-- Success -->
          <b-alert :show="isUpdateSuccessfully" dismissible variant="success">
            {{
              this.$t(
                "datafileResultGeneral.updateDatafileModal.alerts.success"
              )
            }}
          </b-alert>

          <!-- Failed -->
          <b-alert :show="isUpdateFailed" dismissible variant="danger">
            {{
              this.$t("datafileResultGeneral.updateDatafileModal.alerts.failed")
            }}
          </b-alert>

          <!-- Structure Not Match -->
          <b-alert :show="isErrorInStructure" dismissible variant="warning">
            {{
              this.$t(
                "datafileResultGeneral.updateDatafileModal.alerts.notMatch"
              )
            }}
          </b-alert>

          <!-- Other Error -->
          <b-alert :show="otherError != null" dismissible variant="danger">
            {{ otherError }}
          </b-alert>

          <!-- Not Enough Credits -->
          <b-alert :show="isNotEnoughCredits" dismissible variant="danger">
            {{
              this.$t(
                "datafileResultGeneral.updateDatafileModal.alerts.notEnoughCredits"
              )
            }}
          </b-alert>
        </div>
      </div>

      <!-- Upload Datafile Input -->
      <div class="row mb-5">
        <div class="col-md-12">
          <label>
            {{ $t("uploadProcessStep1.form.fileSelector.label") }}
            <span class="text-danger">*</span>
            <span v-if="isDatafileuploadProcessing && !fileReadError">
              <i class="bx bx-loader-circle bx-spin ml-2"></i>
            </span>
            <span
              class="ml-3 text-info pointer"
              v-if="hasDatafileSelected"
              @click="resetDatafile()"
            >
              <i class="mdi mdi-close-circle-outline mr-1"></i>
              {{ $t("uploadProcessStep1.form.fileSelector.removeLabel") }}
            </span>
          </label>
          <div class="custom-file" v-if="!hasDatafileSelected">
            <b-form-file
              id="originalDatafile"
              ref="originalDatafile"
              :placeholder="
                $t('uploadProcessStep1.form.fileSelector.placeholder')
              "
              drop-placeholder="Drop file here..."
              accept=".xlsx"
              @change="selectFile"
            ></b-form-file>
          </div>
          <div v-if="hasDatafileSelected">
            {{ this.newDatafileName }}
          </div>
          <div class="text-danger mt-2" v-if="fileReadError">
            {{ $t("uploadProcessStep1.form.fileSelector.uploadErrorMsg") }}
          </div>
        </div>
      </div>

      <hr />

      <!-- Stat -->
      <div class="row mt-4">
        <div class="col-md-12">
          <label>
            {{
              this.$t(
                "datafileResultGeneral.updateDatafileModal.newDataRecordsLabel"
              )
            }}:
          </label>
          <span class="float-right"> {{ this.newDatarecords }}</span>
        </div>
        <div class="col-md-12">
          <label>
            {{
              this.$t(
                "datafileResultGeneral.updateDatafileModal.totalDataRecordsLabel"
              )
            }}:
          </label>
          <span class="float-right"> {{ this.totalDatarecords }}</span>
        </div>
        <div class="col-md-12">
          <label>
            {{
              this.$t(
                "datafileResultGeneral.updateDatafileModal.newCreditsLabel"
              )
            }}:
          </label>
          <span class="float-right">
            {{
              this.newCredits.toLocaleString(localeFormat, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}</span
          >
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="CleanDatafileCheckbox"
              v-model="cleanDatafileCheckbox"
              :disabled="!isCleaned"
            />
            <label class="custom-control-label" for="CleanDatafileCheckbox" v-b-popover.hover="cleaningCheckboxPopoverContent">
              <span class="font-weight-normal">
                {{
                  $t(
                    "datafileResultGeneral.updateDatafileModal.cleanCheckBoxLabel"
                  )
                }}
              </span>
            </label>
          </div>
        </div>
      </div>

      <!-- Actions -->
      <div class="row mt-3">
        <div class="col-md-12">
          <b-button
            variant="success"
            class="mr-2"
            v-if="hasDatafileSelected && isDatafileMatched"
            @click="updateDatafile()"
            ><i class="bx bx-reset"></i>
            {{
              this.$t("datafileResultGeneral.updateDatafileModal.updateButton")
            }}</b-button
          >
          <b-button variant="light" @click="clickCancel()"
            ><i class="bx bx-x"></i>
            {{
              this.$t("datafileResultGeneral.updateDatafileModal.cancelButton")
            }}</b-button
          >
        </div>
      </div>
    </div>

    <!-- Loading untile data get set --->
    <!--<div class="row justify-content-center" v-else>
      <div class="col-6 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>-->
    <!-- Progress bar  -->
    <div v-else>
      <div class="d-flex justify-content-center">
      <h5>
        {{ $t("datafileResultGeneral.updateDatafileModal.calculating") }}
      </h5>
      </div>
      <div class="m-4">
        <b-progress
          :max="100"
          show-progress
          animated
          height="30px"
          style="font-size: 15px"
        >
          <b-progress-bar
            :value="uploadProgress"
            show-value
          ></b-progress-bar>
        </b-progress>
      </div>
      <div
        class="d-flex"
      >
          <p style="text-align: center">
          {{
            $t(
              "datafileResultGeneral.updateDatafileModal.bePatient.title"
            )
          }}
          <br />
          {{
            $t("datafileResultGeneral.updateDatafileModal.bePatient.text1")
          }}
        </p> 
      </div>
      <div class="p-4 col-md-12 text-center">
        <b-button
            variant="warning"
            @click="clickMakeUpdateBackground()"
            >Back to Datafile
        </b-button>  
      </div>         
    </div>     
  </b-modal>
</template>
