<script>
import { trafficLightClassDecider } from "@/shared/util/chart-helper";
import { INVALID_SCORE } from "@/shared/util/const.js";
import GQSFeedbackModal from "./GQS-feedback-modal.vue";

export default {
  props: {
    itemBatteryScoreObject: Object,
    updateSelectedIBS: Function,
    itemBatterySelectOptions: Array,
    selectedItemBattery: String,
    itemBatteryLineChartData: Array,
    itemBatteryLineChartOptions: Object,
    itemBatteryScoreChart: Object,
    gqsPattern: Object,
    isPatternCheckActivatedForSelectedIBS: Boolean
  },
  components: {
    GQSFeedbackModal
  },
  data() {
    return {
      INVALID_SCORE: null,
      trafficLightClassDecider: trafficLightClassDecider,
      showGQSFeedbackModal: false
    };
  },
  computed: {
    isFbButtonDisabled() {
      return this.gqsPattern.feedback === 'false-positive' || this.gqsPattern.feedback === 'false-negative';
    },
    fbButtonIconClass() {
      let iconClass = "bx bx-dislike bx-xs";
      if (this.gqsPattern.feedback === 'false-positive' || this.gqsPattern.feedback === 'false-negative') {
        iconClass = "bx bxs-dislike bx-xs";
      }
      return iconClass;
    }
  },
  async mounted() {
    this.INVALID_SCORE = INVALID_SCORE;
  }
};
</script>
<template>
  <div>
    <br />
    <!-- IBS Overview -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- IBS Not Valid Msg-->
            <div
              v-if="itemBatteryScoreObject.itemBatteryScore === INVALID_SCORE"
              class="row mb-2"
            >
              <!-- Respondent Not Valid Msg -->
              <div class="col-md-12">
                <b-alert show variant="info">
                  <i class="mdi mdi-alert-circle-outline mr-2"></i>
                  {{ $t("dataRecordModal.general.notDataRecordMsg") }}
                </b-alert>
              </div>
            </div>

            <!-- If IBS is Valid -->
            <div v-else class="row pb-2">
              <div class="col-md-9">
                <!-- Rank Quantile -->
                <div class="row">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.itemBatteryScoreTab.generalSection.quantileRank"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ itemBatteryScoreObject.rankPercentile }}
                  </div>
                </div>
                <!-- Valid Data Points -->
                <div class="row">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.itemBatteryScoreTab.generalSection.validDataPoints"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ itemBatteryScoreObject.validDataPoints }}
                    /
                    {{ itemBatteryScoreObject.totalDataPoints }}
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <apexchart
                  ref="meanChart"
                  class="apex-charts apexChartData"
                  type="radialBar"
                  height="150"
                  dir="ltr"
                  :series="[itemBatteryScoreObject.itemBatteryScore.toFixed(3)]"
                  :options="itemBatteryScoreChart.chartOptions"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Item Baterries -->
    <div
      class="row"
      v-if="
        (itemBatteryScoreObject.itemBatteryScore ||
          itemBatteryScoreObject.itemBatteryScore === 0) &&
          itemBatteryScoreObject.itemBatteryScore !== INVALID_SCORE
      "
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Card Topic -->
            <b-badge class="mb-3" style="font-size: 12px" variant="primary">
              {{
                $t("dataRecordModal.itemBatteryScoreTab.items.title")
              }}</b-badge
            >
            <div
              class="row"
              v-for="(dataPoint,
              dataPointIndex) in itemBatteryScoreObject.datapoints"
              :key="dataPointIndex"
            >
              <div class="col-md-4">
                <label>
                  {{ $t("dataRecordModal.itemBatteryScoreTab.items.text") }} {{dataPointIndex + 1}}
                </label>
              </div>
              <div
                class="col-md-2"
                v-if="dataPoint.itemBatteryScore !== INVALID_SCORE"
              >
                {{ dataPoint.itemBatteryScore.toFixed(3) }}
              </div>
              <div class="col-md-2" v-else>
                {{ $t("dataRecordModal.general.notAvailable") }}
              </div>
              <div v-if="dataPoint.itemBatteryScore !== INVALID_SCORE">
                <!-- Traffic Light -->
                <span>
                  <i
                    class="bx bxs-circle text-primary ml-2"
                    style="font-size: 15px"
                    :class="
                      trafficLightClassDecider(dataPoint.itemBatteryScore)
                    "
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Item Battery Chart -->
    <div
      class="row"
      v-if="itemBatteryScoreObject.itemBatteryScore !== INVALID_SCORE"
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Card Topic -->
            <b-badge class="mb-3" style="font-size: 12px" variant="primary">
              {{
                $t("dataRecordModal.itemBatteryScoreTab.itemBehaviour.title")
              }}
            </b-badge>

            <!-- Chart -->
            <div>
              <div class="row mb-3">
                <div class="col-md-8">
                  <label>
                    {{
                      $t(
                        "dataRecordModal.itemBatteryScoreTab.itemBehaviour.dropdownLabel"
                      )
                    }}: &nbsp;
                  </label>
                  <b-form-select
                    @change="updateSelectedIBS"
                    class="ml-4 col-md-6"
                    v-model="selectedItemBattery"
                    size="sm"
                    :options="itemBatterySelectOptions"
                  >
                  </b-form-select>
                </div>
                <div class="col-md-4 text-right" v-if="itemBatteryLineChartData[0].data.length > 0">
                  <div>
                    <span
                        v-if="gqsPattern.pattern !== '' && gqsPattern.pattern !== 'Valid'"
                        class="badge badge-silver font-size-12"
                        :class="{
                          'gqs-pattern-bad':
                            `${gqsPattern.pattern}` != 'Valid'
                        }"
                      >
                        {{ gqsPattern.pattern }}
                      </span>
                      <b-button
                        v-b-tooltip.hover
                        :title="isFbButtonDisabled ? this.$i18n.t('dataRecordModal.itemBatteryScoreTab.feedbackModal.fbAlreadySent') : ''"
                        :disabled="isFbButtonDisabled"
                        variant="warning"
                        class="btn-sm ml-3"
                        @click="showGQSFeedbackModal = true"
                      >
                        <i :class="fbButtonIconClass"></i>
                      </b-button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <label> {{$t("dataRecordModal.itemBatteryScoreTab.patternCheck.label")}}:</label>
                  <span class="ml-2" v-if="isPatternCheckActivatedForSelectedIBS">
                    <b-badge style="font-size: 12px" variant="info">
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                        )
                      }}
                    </b-badge>
                  </span>
                  <span class="ml-2" v-if="!isPatternCheckActivatedForSelectedIBS">
                    <b-badge style="font-size: 12px" variant="light">
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no"
                        )
                      }}
                    </b-badge>
                  </span>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12" v-if="itemBatteryLineChartData[0].data.length > 0">
                  <apexchart
                    class="apex-charts mt-3"
                    type="line"
                    dir="ltr"
                    height="350"
                    :series="[...itemBatteryLineChartData]"
                    :options="itemBatteryLineChartOptions.chartOptions"
                  ></apexchart>
                </div>
                <div class="col-md-12" v-else>
                  <b-alert show variant="info">
                    <i class="mdi mdi-alert-circle-outline mr-2"></i>
                    {{ $t("dataRecordModal.itemBatteryScoreTab.itemBehaviour.dataNotavailable") }}
                  </b-alert>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <GQSFeedbackModal
      v-if="showGQSFeedbackModal"
      :visible="showGQSFeedbackModal"
      :datapointId="selectedItemBattery"
      @close="showGQSFeedbackModal = false"
    />
  </div>
</template>
