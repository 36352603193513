<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import CleaningService from "@/api/services/cleaning.service.js";
import DatafileService from "@/api/services/datafile.service.js";
import MarkingService from "@/api/services/marking.service.js";
import QuotaService from "@/api/services/quota.service.js";
import DataCleaningMethods from "@/shared/enums/dataCleaningMethods.js";
import { ERROR_CODES } from "@/shared/util/errorCodes.js";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    visible: Boolean,
    datafileId: String
  },
  data() {
    return {
      /******** Data Variables ******/
      datafile: null,
      cleaningSuccessCountdown: 0,
      cleaningFailedCountdown: 0,
      markingingSuccessCountdown: 0,
      cleaningCannotApplyParametersNotSpecificCountdown: 0,
      cleaningCannotApplyNoCleaningMethodSelectedCountdown: 0,
      cleaningFailedMessage: this.$t(
        "datafileResultGeneral.cleanAndMarkModal.errorMsg.general"
      ),
      isLoading: false,
      dismissSecs: 3,
      dismissCountDown: null,
      DataCleaningMethods: DataCleaningMethods,
      showQuotaTarget: false,
      selectedDataCleningMethod: null,
      selectedCleaningSettings: null,
      selectedMarkThreshold: null,
      activatedTab: 0,
      cleaningSettings: null,
      cleaningMethod: null,
      isDatafileCleaned: false,
      markThreshold: null,
      /******** Operational Variables *******/
      isCleaningValidationError: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  created() {
    EventBus.$on("updateQuotaWidget", async () => {
      this.isLoading = true;

      // Get Cleaning Setting and set top the local varibles
      await this.getDatafileCleaningSettings(this.datafileId);

      // Get Quota Setting and set the cleanign method to target sample Size
      await this.getQuoatSettings(this.datafileId);

      this.isLoading = false;
    });
  },
  async mounted() {
    this.isLoading = true;

    // Get Cleaning Setting and set top the local varibles
    await this.getDatafileCleaningSettings(this.datafileId);

    // Get Quota Setting and set the cleanign method to target sample Size
    await this.getQuoatSettings(this.datafileId);

    // Get Datafile
    await this.getDatafile(this.datafileId);

    this.isLoading = false;
  },
  methods: {
    async getDatafile(datafileId) {
      const response = await DatafileService.getDatafile(datafileId);

      if (response) {
        this.datafile = response;
      }
    },
    async getQuoatSettings(datafileId) {
      try {
        this.showQuotaTarget = await QuotaService.getDisplayQuotaTarget(
          datafileId
        );

        if (
          this.showQuotaTarget > 0 &&
          this.cleaningMethod === DataCleaningMethods.TARGET_SAMPLE
        ) {
          this.selectedDataCleningMethod = null;
        }
      } catch (e) {
        this.showQuotaTarget = false;
      }
    },
    async getDatafileCleaningSettings(datafileId) {
      const response = await DatafileService.getDatafileCleaningSettings(
        datafileId
      );

      if (response) {
        this.cleaningMethod = response.cleaningMethod;
        this.isDatafileCleaned = response.isCleaned;
        this.markThreshold = response.markThreshold;
        this.cleaningSettings = response.cleaningSettings;

        this.selectedDataCleningMethod = response.cleaningMethod;
        this.selectedCleaningSettings = response.cleaningSettings;
        this.selectedMarkThreshold = response.markThreshold;
      }
    },
    cleaningFailedCountDownChanged(dismissCountDown) {
      this.cleaningFailedCountdown = dismissCountDown;
    },
    cleaningSuccessCountDownChanged(dismissCountDown) {
      this.cleaningSuccessCountdown = dismissCountDown;
    },
    markingSuccessCountDownChanged(dismissCountDown) {
      this.markingingSuccessCountdown = dismissCountDown;
    },
    cleaningCannotApplyParametersNotSpecific(dismissCountDown) {
      this.cleaningCannotApplyParametersNotSpecificCountdown = dismissCountDown;
    },
    cleaningCannotApplyNoCleaningMethodSelected(dismissCountDown) {
      this.cleaningCannotApplyNoCleaningMethodSelectedCountdown =
        dismissCountDown;
    },
    async clickApplyCleaning() {
      this.isLoading = true;

      if (
        this.selectedDataCleningMethod === DataCleaningMethods.SCORE_THRESHOLD
      ) {
        const allEnableScores =
          this.selectedCleaningSettings.scoreThresholds.filter(
            ({ isEnable }) => isEnable === true
          );

        if (allEnableScores.length === 0) {
          this.isLoading = false;
          this.cleaningCannotApplyParametersNotSpecificCountdown =
            this.dismissSecs;
        } else {
          await this.cleanDatafile();
        }
      } else if (
        this.selectedDataCleningMethod === DataCleaningMethods.TARGET_SAMPLE
      ) {
        if (this.selectedCleaningSettings.targetSampleSize < 1) {
          this.isLoading = false;
          this.cleaningCannotApplyParametersNotSpecificCountdown =
            this.dismissSecs;
        } else {
          await this.cleanDatafile();
        }
      } else if (
        this.selectedDataCleningMethod === DataCleaningMethods.QUOTA_TARGET
      ) {
        await this.cleanDatafile();
      } else {
        this.isLoading = false;
        this.cleaningCannotApplyNoCleaningMethodSelectedCountdown =
          this.dismissSecs;
      }
    },
    async cleanDatafile(dontClean = false) {
      try {
        const response = await CleaningService.cleanDatafile(
          this.datafileId,
          this.selectedCleaningSettings.scoreThresholds,
          this.selectedCleaningSettings.targetSampleSize,
          this.selectedDataCleningMethod,
          dontClean
        );
        if (response) {
          this.isLoading = false;
          this.cleaningSuccessCountdown = this.dismissSecs;
          this.$emit("reload");
        }
      } catch (e) {
        this.isLoading = false;
        this.cleaningFailedCountdown = this.dismissSecs;
        if (e.code === ERROR_CODES.TARGET_SAMPLE_SIZE_TOO_SMALL) {
          const lastIndex = e.message.lastIndexOf(" ");
          const minTargetSampleSize = e.message.slice(lastIndex + 1);
          this.cleaningFailedMessage = this.$t(
            "datafileResultGeneral.cleanAndMarkModal.errorMsg.targetSampleSizeTooSmall",
            { targetSampleSize: minTargetSampleSize }
          );
        }
      }
    },
    async applyMarkFeatureForTheDatafile() {
      this.isLoading = true;
      try {
        const response = await MarkingService.markDatafile(
          this.datafileId,
          this.selectedMarkThreshold
        );
        if (response) {
          this.isLoading = false;
          this.markingingSuccessCountdown = this.dismissSecs;
          this.$emit("reload");
        }
      } catch (e) {
        this.isLoading = false;
        this.cleaningFailedCountdown = this.dismissSecs;
      }
    },
    clickCancel() {
      this.activatedTab = 0;
      this.$emit("close");
    },
    formatScore(value) {
      if (!value || value === undefined) return 0;
      if (value > 100) return 100;
      if (value < 0) return 0;
      return value;
    },
    changeCleanignMethod(event) {
      this.selectedDataCleningMethod = event;
    },
    changeTab(tabNumber) {
      this.activatedTab = tabNumber;
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="p-2">
      <div v-if="!isLoading">
        <!-- Title -->
        <div class="row">
          <div class="col-md-12">
            <h6 class="">
              <i class="bx bx-brush mr-2"></i>
              {{ $t("datafileResultGeneral.cleanAndMarkModal.title") }}
            </h6>
            <p class="" v-if="activatedTab === 0">
              {{ $t("datafileResultGeneral.cleanAndMarkModal.text.clean") }}
            </p>
            <p class="" v-if="activatedTab === 1">
              {{ $t("datafileResultGeneral.cleanAndMarkModal.text.mark") }}
            </p>
          </div>
        </div>

        <!-- Alerts and Info -->
        <div class="row">
          <div class="col-md-12">
            <!-- Warning Message for Not sync with Survey Tool -->
            <b-alert
              :show="datafile.isLiveCleaning === 'STOPPED'"
              class="mt-2"
              variant="warning"
              dismissible
              >{{
                $t(
                  "datafileResultGeneral.cleanAndMarkModal.warningMsg.notSyncWithSurveyTool"
                )
              }}</b-alert
            >

            <!-- Successfully Cleaned Msg -->
            <b-alert
              :show="cleaningSuccessCountdown"
              dismissible
              @dismissed="cleaningSuccessCountdown = 0"
              @dismiss-count-down="cleaningSuccessCountDownChanged"
              class="mt-2"
              variant="success"
              >{{
                $t(
                  "datafileResultGeneral.cleanAndMarkModal.successMsg.cleaning"
                )
              }}</b-alert
            >

            <!-- Successfully Marked Msg -->
            <b-alert
              :show="markingingSuccessCountdown"
              dismissible
              @dismissed="markingingSuccessCountdown = 0"
              @dismiss-count-down="markingSuccessCountDownChanged"
              class="mt-2"
              variant="success"
              >{{
                $t("datafileResultGeneral.cleanAndMarkModal.successMsg.marking")
              }}</b-alert
            >

            <!-- Error in cleaning Msg -->
            <b-alert
              :show="cleaningFailedCountdown"
              dismissible
              @dismissed="cleaningFailedCountdown = 0"
              @dismiss-count-down="cleaningFailedCountDownChanged"
              class="mt-2"
              variant="danger"
              >{{ this.cleaningFailedMessage }}
            </b-alert>

            <!-- Error Not Specific Parameters -->
            <b-alert
              :show="cleaningCannotApplyParametersNotSpecificCountdown"
              dismissible
              @dismissed="cleaningCannotApplyParametersNotSpecificCountdown = 0"
              @dismiss-count-down="cleaningCannotApplyParametersNotSpecific"
              class="mt-2"
              variant="danger"
              >{{
                $t(
                  "datafileResultGeneral.cleanAndMarkModal.errorMsg.cleanignParametersNotSpecific"
                )
              }}
            </b-alert>

            <!-- Error Not selected Cleaning method -->
            <b-alert
              :show="cleaningCannotApplyNoCleaningMethodSelectedCountdown"
              dismissible
              @dismissed="
                cleaningCannotApplyNoCleaningMethodSelectedCountdown = 0
              "
              @dismiss-count-down="cleaningCannotApplyNoCleaningMethodSelected"
              class="mt-2"
              variant="danger"
              >{{
                $t(
                  "datafileResultGeneral.cleanAndMarkModal.errorMsg.cleaningMethodNotSelected"
                )
              }}
            </b-alert>

            <!-- Cleaning Setting Not Apply Banner-->
            <b-alert
              show
              variant="info"
              class="mt-2"
              v-if="!this.isDatafileCleaned"
            >
              {{
                $t(
                  "datafileResultGeneral.cleanAndMarkModal.infoMsg.cleaningNotAppliedYet"
                )
              }}
            </b-alert>

            <!-- Cleaning Setting Already Apply Banner-->
            <b-alert
              show
              variant="success"
              class="mt-2"
              v-if="this.isDatafileCleaned"
            >
              {{
                $t(
                  "datafileResultGeneral.cleanAndMarkModal.infoMsg.cleaningAlreadyApplied"
                )
              }}
              <br />
              <span v-if="cleaningMethod === DataCleaningMethods.TARGET_SAMPLE">
                {{
                  $t(
                    "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.label"
                  )
                }}
                - {{ this.cleaningSettings.targetSampleSize }}</span
              >
              <span
                v-if="cleaningMethod === DataCleaningMethods.SCORE_THRESHOLD"
              >
                <span
                  v-for="(qualityScore, qualityScoreIndex) in this
                    .cleaningSettings.scoreThresholds"
                  :key="qualityScoreIndex"
                >
                  <span v-if="qualityScore.isEnable">
                    <span v-if="qualityScore.key !== 'R' && qualityScore.key !== 'IBS'">
                      {{ qualityScore.key }}
                    </span>
                    <span v-if="qualityScore.key === 'IBS'">
                      GQS
                    </span>
                    <span v-if="qualityScore.key === 'R'">
                      {{ qualityScore.name }}
                    </span>
                    - {{ qualityScore.threshold }},
                  </span>
                </span>
              </span>
            </b-alert>
          </div>
        </div>

        <!-- Tabs -->
        <b-tabs class="mt-3" style="height: 400px" v-model="this.activatedTab">
          <!-- Cleaned Tab -->
          <b-tab class="p-3" lazy active @click="changeTab(0)">
            <template v-slot:title>
              <span class="d-none d-sm-inline-block text-limt">{{
                $t("datafileResultGeneral.cleanAndMarkModal.cleanTabTitle")
              }}</span>
            </template>
            <div class="row">
              <div class="col-md-12">
                <label>{{
                  $t("datafileResultGeneral.cleanAndMarkModal.cleanByLabel")
                }}</label>
              </div>

              <div class="col-md-12">
                <b-form-radio-group
                  v-model="this.selectedDataCleningMethod"
                  @change="changeCleanignMethod"
                  stacked
                >
                  <!-- Target Sample -->
                  <b-form-radio
                    v-if="
                      !showQuotaTarget && datafile.isLiveCleaning !== 'RUNNING'
                    "
                    :value="DataCleaningMethods.TARGET_SAMPLE"
                    class="mb-2"
                    >{{
                      $t(
                        "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.label"
                      )
                    }}
                    <b-badge
                      variant="success"
                      class="ml-2"
                      v-if="
                        isDatafileCleaned &&
                        cleaningMethod === DataCleaningMethods.TARGET_SAMPLE
                      "
                    >
                      {{
                        $t("datafileResultGeneral.cleanAndMarkModal.applyLabel")
                      }}
                    </b-badge>
                  </b-form-radio>

                  <!-- Target Sample Textfield  -->
                  <div
                    class="col-md-12 mt-2 mb-3 ml-1"
                    v-if="
                      this.selectedDataCleningMethod ===
                        DataCleaningMethods.TARGET_SAMPLE && !showQuotaTarget
                    "
                  >
                    <b-input
                      v-model.number="selectedCleaningSettings.targetSampleSize"
                      type="number"
                      min="0"
                      max="1000000"
                      :placeholder="
                        this.$t(
                          'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
                        )
                      "
                    />
                  </div>

                  <!-- Score threshold -->
                  <b-form-radio
                    :value="DataCleaningMethods.SCORE_THRESHOLD"
                    class="mb-2"
                    >{{
                      $t(
                        "datafileResultGeneral.cleanAndMarkModal.cleaningOption2.label"
                      )
                    }}
                    <b-badge
                      class="ml-2"
                      variant="success"
                      v-if="
                        isDatafileCleaned &&
                        cleaningMethod === DataCleaningMethods.SCORE_THRESHOLD
                      "
                    >
                      {{
                        $t("datafileResultGeneral.cleanAndMarkModal.applyLabel")
                      }}
                    </b-badge>
                  </b-form-radio>

                  <!-- Score threshold Selections  -->
                  <div
                    class="col-md-12 mt-2 mb-3"
                    v-if="
                      this.selectedDataCleningMethod ===
                      DataCleaningMethods.SCORE_THRESHOLD
                    "
                  >
                    <div
                      class="row mb-1 ml-1 align-items-center"
                      v-for="(score, scoreIndex) in this
                        .selectedCleaningSettings.scoreThresholds"
                      :key="scoreIndex"
                    >
                      <div class="col-md-1">
                        <input
                          class="text-primary"
                          type="checkbox"
                          v-model="score.isEnable"
                        />
                      </div>
                      <div class="col-md-7">
                        <span v-if="score.key === 'IBS'">
                          Grid Question Score
                        </span>
                        <span v-if="score.key !== 'IBS'">
                          {{ score.name }}
                        </span>
                        <span v-if="score.key !== 'R' && score.key !== 'IBS'">
                          ({{ score.key }})
                        </span>
                        <span v-if="score.key === 'IBS'">
                          (GQS)
                        </span>
                      </div>
                      <div class="col-md-4">
                        <b-form-input
                          v-if="!score.isEnable"
                          v-model.number="score.threshold"
                          disabled
                          style="
                            background: #f5f5f5;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                          "
                          :placeholder="
                            $t(
                              'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
                            )
                          "
                        >
                        </b-form-input>
                        <b-form-input
                          v-else
                          v-model.number="score.threshold"
                          type="number"
                          :min="0"
                          :max="100"
                          :placeholder="
                            $t(
                              'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
                            )
                          "
                          :formatter="formatScore"
                        >
                        </b-form-input>
                      </div>
                    </div>
                  </div>

                  <!-- Quota Cleaning -->
                  <b-form-radio
                    v-if="showQuotaTarget"
                    :value="DataCleaningMethods.QUOTA_TARGET"
                    class="mb-1"
                    :disabled="showQuotaTarget === 1"
                    >{{
                      $t(
                        "datafileResultGeneral.cleanAndMarkModal.cleaningOption3.label"
                      )
                    }}
                    <img
                      class="mb-1"
                      src="@/assets/images/beta-icon.png"
                      width="50"
                    />
                    <b-badge
                      class="ml-2"
                      variant="success"
                      v-if="
                        isDatafileCleaned &&
                        cleaningMethod === DataCleaningMethods.QUOTA_TARGET
                      "
                    >
                      {{
                        $t("datafileResultGeneral.cleanAndMarkModal.applyLabel")
                      }}
                    </b-badge>
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </b-tab>

          <!-- Mark Tab -->
          <b-tab class="p-3" lazy @click="changeTab(1)">
            <template v-slot:title>
              <span class="d-none d-sm-inline-block text-limt">{{
                $t("datafileResultGeneral.cleanAndMarkModal.markTabTitle")
              }}</span>
            </template>
            <div class="row">
              <div class="col-md-12">
                <p>
                  {{
                    $t(
                      "datafileResultGeneral.cleanAndMarkModal.mark.information"
                    )
                  }}
                </p>
              </div>

              <div class="col-md-12 mt-2">
                <b-form-input
                  v-model.number="selectedMarkThreshold"
                  type="number"
                  :placeholder="
                    this.$t(
                      'datafileResultGeneral.cleanAndMarkModal.mark.placeholder'
                    )
                  "
                  :formatter="formatScore"
                >
                </b-form-input>
              </div>
            </div>
          </b-tab>
        </b-tabs>

        <!-- Actions -->
        <div class="row mt-4">
          <div class="col-md-12">
            <b-button
              v-if="activatedTab === 0"
              @click="clickApplyCleaning()"
              variant="success"
              class="mr-2"
              ><i class="bx bx-brush"></i>
              <span
                v-if="
                  datafile.isLiveCleaning === 'RUNNING' && datafile.isCleaned
                "
              >
                {{
                  $t(
                    "datafileResultGeneral.cleanAndMarkModal.changeCleaningButton"
                  )
                }}
              </span>
              <span v-else>
                {{
                  $t(
                    "datafileResultGeneral.cleanAndMarkModal.applyCleaningButton"
                  )
                }}</span
              >
            </b-button>
            <b-button
              v-if="activatedTab === 1"
              @click="applyMarkFeatureForTheDatafile()"
              variant="success"
              class="mr-2"
              ><i class="bx bxs-bookmark"></i>
              {{
                $t("datafileResultGeneral.cleanAndMarkModal.markButton")
              }}</b-button
            >
            <b-button @click="clickCancel()" variant="light"
              ><i class="bx bx-x"></i>
              {{
                $t("datafileResultGeneral.cleanAndMarkModal.cancelButton")
              }}</b-button
            >
          </div>
        </div>
      </div>

      <!-- Loading until cleaning datafile is finished --->
      <div class="row justify-content-center" v-else>
        <div class="col-6 text-center p-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>
  </b-modal>
</template>
