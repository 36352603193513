var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        scrollable: "",
        "hide-header-close": "",
        size: "xl",
      },
      scopedSlots: _vm._u(
        [
          this.displayData
            ? {
                key: "modal-header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "row", staticStyle: { width: "100vw" } },
                      [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "media" }, [
                            _c(
                              "div",
                              { staticClass: "mr-3" },
                              [
                                _c("v-gravatar", {
                                  staticClass: "rounded-circle",
                                  attrs: {
                                    email: String(_vm.dataRecord),
                                    size: "45",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "media-body overflow-hidden" },
                              [
                                _c(
                                  "h5",
                                  { staticClass: "mb-1 mt-1 font-size-14" },
                                  [_vm._v(" " + _vm._s(_vm.dataRecord) + " ")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "dataRecordModal.general.dataRecordNumber"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-6 text-right" },
                          [
                            !_vm.overviewDataObject.isIncluded
                              ? _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "danger" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "dataRecordModal.general.excludedBadge"
                                          )
                                        ) +
                                        " - "
                                    ),
                                    _vm.overviewDataObject.excludedReason ===
                                    "Manually"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dataRecordModal.general.excludedReason.manual"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.overviewDataObject.excludedReason ===
                                    "Quota"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dataRecordModal.general.excludedReason.quota"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.overviewDataObject.excludedReason ===
                                    "R-Score"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dataRecordModal.general.excludedReason.rScore"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.overviewDataObject.excludedReason ===
                                    "CS"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dataRecordModal.general.excludedReason.CS"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.overviewDataObject.excludedReason ===
                                    "PS"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dataRecordModal.general.excludedReason.PS"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.overviewDataObject.excludedReason ===
                                    "TS"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dataRecordModal.general.excludedReason.TS"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.overviewDataObject.excludedReason ===
                                    "IBS"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dataRecordModal.general.excludedReason.IBS"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.overviewDataObject.excludedReason ===
                                    "SDS"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dataRecordModal.general.excludedReason.SDS"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.overviewDataObject.excludedReason ===
                                    "IS"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dataRecordModal.general.excludedReason.IS"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.overviewDataObject.excludedReason ===
                                    "OES"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "dataRecordModal.general.excludedReason.OES"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _c("i", {
                              staticClass: "bx bxs-circle text-primary ml-2",
                              class: _vm.trafficLightClassDecider(
                                _vm.overviewDataObject.datafileRScore
                              ),
                              staticStyle: { "font-size": "15px" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      this.displayData
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _vm.displayData
                ? _c(
                    "div",
                    { staticClass: "col-md-12 mb-4" },
                    [
                      _c(
                        "b-tabs",
                        {
                          attrs: { "content-class": "pt-3" },
                          model: {
                            value: _vm.activatedTabOP,
                            callback: function ($$v) {
                              _vm.activatedTabOP = $$v
                            },
                            expression: "activatedTabOP",
                          },
                        },
                        [
                          this.numberOfQualityScores > 1
                            ? _c(
                                "b-tab",
                                {
                                  attrs: { lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block d-sm-none",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bx-notification",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-sm-inline-block",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataRecordModal.general.tabs.summary"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4222344825
                                  ),
                                },
                                [
                                  _c("OverviewTab", {
                                    attrs: {
                                      overviewDataObject:
                                        this.overviewDataObject,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.includeOES
                            ? _c(
                                "b-tab",
                                {
                                  attrs: { "content-class": "pt-3", lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block d-sm-none",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bx-notification",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-sm-inline-block",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataRecordModal.general.tabs.openEndedScore"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2498141191
                                  ),
                                },
                                [
                                  _c("OESTab", {
                                    attrs: {
                                      lazy: "",
                                      openEndedScoreChartOptions:
                                        _vm.openEndedScoreChartOptions,
                                      openEndedScoreObject:
                                        _vm.openEndedScoreObject,
                                      datafileId: _vm.datafile,
                                      dataRecordId: _vm.dataRecord,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.includeTimestamp
                            ? _c(
                                "b-tab",
                                {
                                  attrs: { "content-class": "pt-3", lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block d-sm-none",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bx-notification",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-sm-inline-block",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataRecordModal.general.tabs.timeScore"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1695306760
                                  ),
                                },
                                [
                                  _c("TSTab", {
                                    attrs: {
                                      lazy: "",
                                      timeScoreDataObject:
                                        _vm.timeScoreDataObject,
                                      timeScoreChart: _vm.timeScoreChart,
                                      timeScoreComparisonChartData:
                                        _vm.timeScoreComparisonChartData,
                                      timeScoreComparisonChartOptions:
                                        _vm.timeScoreComparisonChartOptions,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.includeIBS
                            ? _c(
                                "b-tab",
                                {
                                  attrs: { "content-class": "pt-3", lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block d-sm-none",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bx-notification",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-sm-inline-block",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataRecordModal.general.tabs.itemBatteryScore"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2406098021
                                  ),
                                },
                                [
                                  _c("IBSTab", {
                                    attrs: {
                                      lazy: "",
                                      itemBatteryScoreChart:
                                        _vm.itemBatteryScoreChart,
                                      itemBatteryLineChartOptions:
                                        _vm.itemBatteryLineChartOptions,
                                      itemBatterySelectOptions:
                                        _vm.itemBatterySelectOptions,
                                      itemBatteryScoreObject:
                                        _vm.itemBatteryScoreObject,
                                      selectedItemBattery:
                                        _vm.selectedItemBattery,
                                      updateSelectedIBS: _vm.updateSelectedIBS,
                                      itemBatteryLineChartData:
                                        _vm.ItemBatteryLineChartData,
                                      gqsPattern: _vm.selectedGQSPattern,
                                      isPatternCheckActivatedForSelectedIBS:
                                        _vm.isPatternCheckActivatedForSelectedIBS,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.includePS
                            ? _c(
                                "b-tab",
                                {
                                  attrs: { "content-class": "pt-3", lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block d-sm-none",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bx-notification",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-sm-inline-block",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataRecordModal.general.tabs.predictionScore"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2887446408
                                  ),
                                },
                                [
                                  _c("PSTab", {
                                    attrs: {
                                      lazy: "",
                                      pScoreChart: _vm.pScoreChart,
                                      psAvgPredictionChartOptions:
                                        _vm.psAvgPredictionChartOptions,
                                      showPSAnswerChart: _vm.showPSAnswerChart,
                                      PSSelectOptions: _vm.PSSelectOptions,
                                      predictionScoreObject:
                                        _vm.predictionScoreObject,
                                      selectedPSDatapoint:
                                        _vm.selectedPSDatapoint,
                                      updateSelectedPS: _vm.updateSelectedPS,
                                      chartDataForPScorePerformanceChart:
                                        _vm.chartDataForPScorePerformanceChart,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.includeCS
                            ? _c(
                                "b-tab",
                                {
                                  attrs: { "content-class": "pt-3", lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block d-sm-none",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bx-notification",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-none d-sm-inline-block",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataRecordModal.general.tabs.certaintyScore"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4240654966
                                  ),
                                },
                                [
                                  _c("CSTab", {
                                    attrs: {
                                      lazy: "",
                                      cScoreChart: _vm.cScoreChart,
                                      csAvgPredictionChartOptions:
                                        _vm.csAvgPredictionChartOptions,
                                      selectedCSDatapoint:
                                        _vm.selectedCSDatapoint,
                                      CSSelectOptions: _vm.CSSelectOptions,
                                      certaintyScoreObject:
                                        _vm.certaintyScoreObject,
                                      selectedCSDatapointObject:
                                        _vm.selectedCSDatapointObject,
                                      updateSelectedCS: _vm.updateSelectedCS,
                                      chartDataForCScorePerformanceChart:
                                        _vm.chartDataForCScorePerformanceChart,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      !this.displayData && !_vm.isRespondantOpenError
        ? _c(
            "div",
            { staticClass: "col-12 text-center p-5" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          )
        : _vm._e(),
      !this.displayData && _vm.isRespondantOpenError
        ? _c(
            "div",
            { staticClass: "col-12 text-center p-5" },
            [
              _vm.isRespondantOpenError
                ? _c("div", { staticClass: "d-flex justify-content-center" }, [
                    _c("h5", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("dataRecordModal.general.error.title")
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.isRespondantOpenError
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-center mb-3" },
                    [
                      _c("p", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("dataRecordModal.general.error.text")
                            ) +
                            " "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:support@redem.io?subject=Probleme beim Hochladen des Respondant Modal",
                            },
                          },
                          [_vm._v("support@redem.io")]
                        ),
                        _vm._v(". "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "danger" },
                  model: {
                    value: _vm.isRespondantOpenError,
                    callback: function ($$v) {
                      _vm.isRespondantOpenError = $$v
                    },
                    expression: "isRespondantOpenError",
                  },
                },
                [_vm._v(_vm._s(_vm.respondantOpenErrorMsg) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }