var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "p-2" }, [
        !_vm.isLoading
          ? _c(
              "div",
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("h6", {}, [
                      _c("i", { staticClass: "bx bx-brush mr-2" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultGeneral.cleanAndMarkModal.title"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _vm.activatedTab === 0
                      ? _c("p", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultGeneral.cleanAndMarkModal.text.clean"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.activatedTab === 1
                      ? _c("p", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultGeneral.cleanAndMarkModal.text.mark"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(
                        "b-alert",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            show: _vm.datafile.isLiveCleaning === "STOPPED",
                            variant: "warning",
                            dismissible: "",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.cleanAndMarkModal.warningMsg.notSyncWithSurveyTool"
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "b-alert",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            show: _vm.cleaningSuccessCountdown,
                            dismissible: "",
                            variant: "success",
                          },
                          on: {
                            dismissed: function ($event) {
                              _vm.cleaningSuccessCountdown = 0
                            },
                            "dismiss-count-down":
                              _vm.cleaningSuccessCountDownChanged,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.cleanAndMarkModal.successMsg.cleaning"
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "b-alert",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            show: _vm.markingingSuccessCountdown,
                            dismissible: "",
                            variant: "success",
                          },
                          on: {
                            dismissed: function ($event) {
                              _vm.markingingSuccessCountdown = 0
                            },
                            "dismiss-count-down":
                              _vm.markingSuccessCountDownChanged,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.cleanAndMarkModal.successMsg.marking"
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "b-alert",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            show: _vm.cleaningFailedCountdown,
                            dismissible: "",
                            variant: "danger",
                          },
                          on: {
                            dismissed: function ($event) {
                              _vm.cleaningFailedCountdown = 0
                            },
                            "dismiss-count-down":
                              _vm.cleaningFailedCountDownChanged,
                          },
                        },
                        [_vm._v(_vm._s(this.cleaningFailedMessage) + " ")]
                      ),
                      _c(
                        "b-alert",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            show: _vm.cleaningCannotApplyParametersNotSpecificCountdown,
                            dismissible: "",
                            variant: "danger",
                          },
                          on: {
                            dismissed: function ($event) {
                              _vm.cleaningCannotApplyParametersNotSpecificCountdown = 0
                            },
                            "dismiss-count-down":
                              _vm.cleaningCannotApplyParametersNotSpecific,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.cleanAndMarkModal.errorMsg.cleanignParametersNotSpecific"
                              )
                            ) + " "
                          ),
                        ]
                      ),
                      _c(
                        "b-alert",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            show: _vm.cleaningCannotApplyNoCleaningMethodSelectedCountdown,
                            dismissible: "",
                            variant: "danger",
                          },
                          on: {
                            dismissed: function ($event) {
                              _vm.cleaningCannotApplyNoCleaningMethodSelectedCountdown = 0
                            },
                            "dismiss-count-down":
                              _vm.cleaningCannotApplyNoCleaningMethodSelected,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.cleanAndMarkModal.errorMsg.cleaningMethodNotSelected"
                              )
                            ) + " "
                          ),
                        ]
                      ),
                      !this.isDatafileCleaned
                        ? _c(
                            "b-alert",
                            {
                              staticClass: "mt-2",
                              attrs: { show: "", variant: "info" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultGeneral.cleanAndMarkModal.infoMsg.cleaningNotAppliedYet"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      this.isDatafileCleaned
                        ? _c(
                            "b-alert",
                            {
                              staticClass: "mt-2",
                              attrs: { show: "", variant: "success" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultGeneral.cleanAndMarkModal.infoMsg.cleaningAlreadyApplied"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm.cleaningMethod ===
                              _vm.DataCleaningMethods.TARGET_SAMPLE
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.label"
                                          )
                                        ) +
                                        " - " +
                                        _vm._s(
                                          this.cleaningSettings.targetSampleSize
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.cleaningMethod ===
                              _vm.DataCleaningMethods.SCORE_THRESHOLD
                                ? _c(
                                    "span",
                                    _vm._l(
                                      this.cleaningSettings.scoreThresholds,
                                      function (
                                        qualityScore,
                                        qualityScoreIndex
                                      ) {
                                        return _c(
                                          "span",
                                          { key: qualityScoreIndex },
                                          [
                                            qualityScore.isEnable
                                              ? _c("span", [
                                                  qualityScore.key !== "R" &&
                                                  qualityScore.key !== "IBS"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              qualityScore.key
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  qualityScore.key === "IBS"
                                                    ? _c("span", [
                                                        _vm._v(" GQS "),
                                                      ])
                                                    : _vm._e(),
                                                  qualityScore.key === "R"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              qualityScore.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " - " +
                                                      _vm._s(
                                                        qualityScore.threshold
                                                      ) +
                                                      ", "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c(
                  "b-tabs",
                  {
                    staticClass: "mt-3",
                    staticStyle: { height: "400px" },
                    model: {
                      value: this.activatedTab,
                      callback: function ($$v) {
                        _vm.$set(this, "activatedTab", $$v)
                      },
                      expression: "this.activatedTab",
                    },
                  },
                  [
                    _c(
                      "b-tab",
                      {
                        staticClass: "p-3",
                        attrs: { lazy: "", active: "" },
                        on: {
                          click: function ($event) {
                            return _vm.changeTab(0)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-none d-sm-inline-block text-limt",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.cleanAndMarkModal.cleanTabTitle"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3372777935
                        ),
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "datafileResultGeneral.cleanAndMarkModal.cleanByLabel"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  attrs: { stacked: "" },
                                  on: { change: _vm.changeCleanignMethod },
                                  model: {
                                    value: this.selectedDataCleningMethod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        this,
                                        "selectedDataCleningMethod",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "this.selectedDataCleningMethod",
                                  },
                                },
                                [
                                  !_vm.showQuotaTarget &&
                                  _vm.datafile.isLiveCleaning !== "RUNNING"
                                    ? _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "mb-2",
                                          attrs: {
                                            value:
                                              _vm.DataCleaningMethods
                                                .TARGET_SAMPLE,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.label"
                                              )
                                            ) + " "
                                          ),
                                          _vm.isDatafileCleaned &&
                                          _vm.cleaningMethod ===
                                            _vm.DataCleaningMethods
                                              .TARGET_SAMPLE
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: { variant: "success" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "datafileResultGeneral.cleanAndMarkModal.applyLabel"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  this.selectedDataCleningMethod ===
                                    _vm.DataCleaningMethods.TARGET_SAMPLE &&
                                  !_vm.showQuotaTarget
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-md-12 mt-2 mb-3 ml-1",
                                        },
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              type: "number",
                                              min: "0",
                                              max: "1000000",
                                              placeholder: this.$t(
                                                "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                                              ),
                                            },
                                            model: {
                                              value:
                                                _vm.selectedCleaningSettings
                                                  .targetSampleSize,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedCleaningSettings,
                                                  "targetSampleSize",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "selectedCleaningSettings.targetSampleSize",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        value:
                                          _vm.DataCleaningMethods
                                            .SCORE_THRESHOLD,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.cleanAndMarkModal.cleaningOption2.label"
                                          )
                                        ) + " "
                                      ),
                                      _vm.isDatafileCleaned &&
                                      _vm.cleaningMethod ===
                                        _vm.DataCleaningMethods.SCORE_THRESHOLD
                                        ? _c(
                                            "b-badge",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { variant: "success" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "datafileResultGeneral.cleanAndMarkModal.applyLabel"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  this.selectedDataCleningMethod ===
                                  _vm.DataCleaningMethods.SCORE_THRESHOLD
                                    ? _c(
                                        "div",
                                        { staticClass: "col-md-12 mt-2 mb-3" },
                                        _vm._l(
                                          this.selectedCleaningSettings
                                            .scoreThresholds,
                                          function (score, scoreIndex) {
                                            return _c(
                                              "div",
                                              {
                                                key: scoreIndex,
                                                staticClass:
                                                  "row mb-1 ml-1 align-items-center",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-1" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: score.isEnable,
                                                          expression:
                                                            "score.isEnable",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "text-primary",
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          score.isEnable
                                                        )
                                                          ? _vm._i(
                                                              score.isEnable,
                                                              null
                                                            ) > -1
                                                          : score.isEnable,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              score.isEnable,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  score,
                                                                  "isEnable",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  score,
                                                                  "isEnable",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              score,
                                                              "isEnable",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-7" },
                                                  [
                                                    score.key === "IBS"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " Grid Question Score "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    score.key !== "IBS"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                score.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    score.key !== "R" &&
                                                    score.key !== "IBS"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " (" +
                                                              _vm._s(
                                                                score.key
                                                              ) +
                                                              ") "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    score.key === "IBS"
                                                      ? _c("span", [
                                                          _vm._v(" (GQS) "),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-4" },
                                                  [
                                                    !score.isEnable
                                                      ? _c("b-form-input", {
                                                          staticStyle: {
                                                            background:
                                                              "#f5f5f5",
                                                            "-webkit-appearance":
                                                              "none",
                                                            "-moz-appearance":
                                                              "none",
                                                          },
                                                          attrs: {
                                                            disabled: "",
                                                            placeholder: _vm.$t(
                                                              "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                                                            ),
                                                          },
                                                          model: {
                                                            value:
                                                              score.threshold,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                score,
                                                                "threshold",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "score.threshold",
                                                          },
                                                        })
                                                      : _c("b-form-input", {
                                                          attrs: {
                                                            type: "number",
                                                            min: 0,
                                                            max: 100,
                                                            placeholder: _vm.$t(
                                                              "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                                                            ),
                                                            formatter:
                                                              _vm.formatScore,
                                                          },
                                                          model: {
                                                            value:
                                                              score.threshold,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                score,
                                                                "threshold",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "score.threshold",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm.showQuotaTarget
                                    ? _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "mb-1",
                                          attrs: {
                                            value:
                                              _vm.DataCleaningMethods
                                                .QUOTA_TARGET,
                                            disabled: _vm.showQuotaTarget === 1,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "datafileResultGeneral.cleanAndMarkModal.cleaningOption3.label"
                                              )
                                            ) + " "
                                          ),
                                          _c("img", {
                                            staticClass: "mb-1",
                                            attrs: {
                                              src: require("@/assets/images/beta-icon.png"),
                                              width: "50",
                                            },
                                          }),
                                          _vm.isDatafileCleaned &&
                                          _vm.cleaningMethod ===
                                            _vm.DataCleaningMethods.QUOTA_TARGET
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: { variant: "success" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "datafileResultGeneral.cleanAndMarkModal.applyLabel"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        staticClass: "p-3",
                        attrs: { lazy: "" },
                        on: {
                          click: function ($event) {
                            return _vm.changeTab(1)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-none d-sm-inline-block text-limt",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.cleanAndMarkModal.markTabTitle"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4172041951
                        ),
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultGeneral.cleanAndMarkModal.mark.information"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-12 mt-2" },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: this.$t(
                                    "datafileResultGeneral.cleanAndMarkModal.mark.placeholder"
                                  ),
                                  formatter: _vm.formatScore,
                                },
                                model: {
                                  value: _vm.selectedMarkThreshold,
                                  callback: function ($$v) {
                                    _vm.selectedMarkThreshold = _vm._n($$v)
                                  },
                                  expression: "selectedMarkThreshold",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "row mt-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _vm.activatedTab === 0
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickApplyCleaning()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "bx bx-brush" }),
                              _vm.datafile.isLiveCleaning === "RUNNING" &&
                              _vm.datafile.isCleaned
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.cleanAndMarkModal.changeCleaningButton"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultGeneral.cleanAndMarkModal.applyCleaningButton"
                                          )
                                        )
                                    ),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.activatedTab === 1
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.applyMarkFeatureForTheDatafile()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "bx bxs-bookmark" }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultGeneral.cleanAndMarkModal.markButton"
                                    )
                                  )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "light" },
                          on: {
                            click: function ($event) {
                              return _vm.clickCancel()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "bx bx-x" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "datafileResultGeneral.cleanAndMarkModal.cancelButton"
                                )
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          : _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-6 text-center p-5" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }