var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "", "hide-header": "", size: "xl" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("h6", [
            _c("i", { staticClass: "bx bx-highlight mr-2" }),
            _vm._v(
              _vm._s(_vm.$t("datafileResultOESTab.suggestScore.header")) + " "
            ),
          ]),
          _c("p", { staticClass: "mt-3 mb-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("datafileResultOESTab.suggestScore.description")
                ) +
                " "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-alert",
              {
                staticClass: "col-md-12",
                attrs: {
                  show: _vm.suggestScoreSuccessCountdown,
                  dismissible: "",
                  variant: "success",
                },
                on: {
                  dismissed: function ($event) {
                    _vm.suggestScoreSuccessCountdown = 0
                  },
                  "dismiss-count-down": _vm.suggestScoreSuccessCountdownChanged,
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("datafileResultOESTab.suggestScore.success"))
                ),
              ]
            ),
            _c(
              "b-alert",
              {
                staticClass: "col-md-12",
                attrs: {
                  show: _vm.suggestScoreFailedCountdown,
                  dismissible: "",
                  variant: "danger",
                },
                on: {
                  dismissed: function ($event) {
                    _vm.suggestScoreFailedCountdown = 0
                  },
                  "dismiss-count-down": _vm.suggestScoreFailedCountdownChanged,
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("datafileResultOESTab.suggestScore.failure")) +
                    " "
                ),
              ]
            ),
            _c(
              "b-alert",
              {
                staticClass: "col-md-12",
                attrs: {
                  show: _vm.suggestScoreValidationErrorCountdown,
                  dismissible: "",
                  variant: "danger",
                },
                on: {
                  dismissed: function ($event) {
                    _vm.suggestScoreValidationErrorCountdown = 0
                  },
                  "dismiss-count-down":
                    _vm.suggestScoreValidationErrorCountdownChanged,
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("datafileResultOESTab.suggestScore.requiredErrorMsg")
                  ) + " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 mb-2" },
          [
            _c(
              "b-badge",
              { staticClass: "font-size-12", attrs: { variant: "primary" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("datafileResultOESTab.suggestScore.questionTitle")
                    ) +
                    ": "
                ),
              ]
            ),
            _vm._v(" " + _vm._s(this.questionTitle) + " "),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-12 mb-2" },
          [
            _c(
              "b-badge",
              { staticClass: "font-size-12", attrs: { variant: "success" } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("datafileResultOESTab.suggestScore.answer")) +
                    ": "
                ),
              ]
            ),
            _vm._v(
              " " + _vm._s(this.selectedDataRecord.answerFromRespondent) + " "
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-12 mb-2" },
          [
            _c(
              "b-badge",
              { staticClass: "font-size-12", attrs: { variant: "warning" } },
              [_vm._v(" Score: ")]
            ),
            _vm._v(" " + _vm._s(this.selectedDataRecord.qualityScore) + " "),
          ],
          1
        ),
        this.selectedDataRecord.suggestedBy
          ? _c("div", { staticClass: "col-md-12" }, [
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("datafileResultOESTab.suggestScore.suggestedBy")
                    ) +
                    " "
                ),
              ]),
              _vm._v(" " + _vm._s(this.selectedDataRecord.suggestedBy) + " "),
            ])
          : _vm._e(),
      ]),
      !this.isLoading
        ? _c("div", [
            _c("hr"),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultOESTab.suggestScore.score.label"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "number",
                          min: "0",
                          max: "100",
                          placeholder: _vm.$t(
                            "datafileResultOESTab.suggestScore.score.placeholder"
                          ),
                          formatter: _vm.formatScore,
                        },
                        model: {
                          value: _vm.suggestedScore,
                          callback: function ($$v) {
                            _vm.suggestedScore = _vm._n($$v)
                          },
                          expression: "suggestedScore",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row mt-4" }, [
                  _c("div", { staticClass: "col-md-12 mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "datafileResultOESTab.suggestScore.scoreInfo.text"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("ul", [
                    _c("li", [
                      _c("div", { staticClass: "col-md-12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultOESTab.suggestScore.scoreInfo.range1"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "col-md-12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultOESTab.suggestScore.scoreInfo.range2"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "col-md-12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultOESTab.suggestScore.scoreInfo.range3"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "col-md-12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultOESTab.suggestScore.scoreInfo.range4"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-2" }),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("datafileResultOESTab.suggestScore.reason")
                          ) +
                          ": "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-form-radio-group",
                          {
                            attrs: { stacked: "" },
                            model: {
                              value: _vm.reasonForSuggestedScore,
                              callback: function ($$v) {
                                _vm.reasonForSuggestedScore = $$v
                              },
                              expression: "reasonForSuggestedScore",
                            },
                          },
                          [
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "mb-2",
                                attrs: { value: this.reasonsForScores.reason1 },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultOESTab.suggestScore.reason1"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "mb-2",
                                attrs: { value: this.reasonsForScores.reason2 },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultOESTab.suggestScore.reason2"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "mb-2",
                                attrs: { value: this.reasonsForScores.reason3 },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultOESTab.suggestScore.reason3"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "mb-2",
                                attrs: { value: this.reasonsForScores.reason4 },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultOESTab.suggestScore.reason4"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "mb-2",
                                attrs: { value: this.reasonsForScores.reason5 },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultOESTab.suggestScore.reason5"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "mb-2",
                                attrs: { value: _vm.reasonsForScores.reason6 },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultOESTab.suggestScore.reasonOther"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  this.reasonForSuggestedScore === this.reasonsForScores.reason6
                    ? _c("div", { staticClass: "col-md-12 mt-3" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.commentOnSuggestedScore,
                              expression: "commentOnSuggestedScore",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "textarea",
                            rows: "3",
                            placeholder: this.$t(
                              "datafileResultOESTab.suggestScore.commentPlaceholder"
                            ),
                          },
                          domProps: { value: _vm.commentOnSuggestedScore },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.commentOnSuggestedScore = $event.target.value
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c("hr"),
            _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      model: {
                        value: _vm.expandDictionary,
                        callback: function ($$v) {
                          _vm.expandDictionary = $$v
                        },
                        expression: "expandDictionary",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultOESTab.suggestScore.expandDictionary.label"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  this.expandDictionary
                    ? _c("vue-tags-input", {
                        staticClass: "mt-2",
                        attrs: {
                          tags: _vm.addDictionaryWords,
                          placeholder: _vm.$t(
                            "datafileResultOESTab.suggestScore.expandDictionary.placeholder"
                          ),
                        },
                        on: {
                          "tags-changed": (newTags) =>
                            (_vm.addDictionaryWords = newTags),
                        },
                        model: {
                          value: _vm.vue_tag,
                          callback: function ($$v) {
                            _vm.vue_tag = $$v
                          },
                          expression: "vue_tag",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              this.expandDictionary
                ? _c("div", { staticClass: "col-md-4" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultOESTab.suggestScore.language.label"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.dictionaryLanguage,
                            expression: "dictionaryLanguage",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: this.$t(
                            "datafileResultOESTab.suggestScore.language.placeholder"
                          ),
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.dictionaryLanguage = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          {
                            domProps: {
                              value: _vm.languagesForDictionary[0].value,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$t(_vm.languagesForDictionary[0].name)
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "option",
                          {
                            domProps: {
                              value: _vm.languagesForDictionary[1].value,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$t(_vm.languagesForDictionary[1].name)
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "option",
                          {
                            domProps: {
                              value: _vm.languagesForDictionary[2].value,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$t(_vm.languagesForDictionary[2].name)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "row mt-5" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: { variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSuggestScore()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "bx bx-save" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultOESTab.suggestScore.action.suggest"
                            )
                          )
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "light" },
                      on: {
                        click: function ($event) {
                          return _vm.clickClose()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "bx bx-x" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultOESTab.suggestScore.action.cancel"
                            )
                          )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      this.isLoading
        ? _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-12 text-center" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }