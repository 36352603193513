var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("br"),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _vm.itemBatteryScoreObject.itemBatteryScore === _vm.INVALID_SCORE
                ? _c("div", { staticClass: "row mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c(
                          "b-alert",
                          { attrs: { show: "", variant: "info" } },
                          [
                            _c("i", {
                              staticClass: "mdi mdi-alert-circle-outline mr-2",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "dataRecordModal.general.notDataRecordMsg"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _c("div", { staticClass: "row pb-2" }, [
                    _c("div", { staticClass: "col-md-9" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-4" }, [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "dataRecordModal.itemBatteryScoreTab.generalSection.quantileRank"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-8" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.itemBatteryScoreObject.rankPercentile
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-4" }, [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "dataRecordModal.itemBatteryScoreTab.generalSection.validDataPoints"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-8" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.itemBatteryScoreObject.validDataPoints
                              ) +
                              " / " +
                              _vm._s(
                                _vm.itemBatteryScoreObject.totalDataPoints
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("apexchart", {
                          ref: "meanChart",
                          staticClass: "apex-charts apexChartData",
                          attrs: {
                            type: "radialBar",
                            height: "150",
                            dir: "ltr",
                            series: [
                              _vm.itemBatteryScoreObject.itemBatteryScore.toFixed(
                                3
                              ),
                            ],
                            options: _vm.itemBatteryScoreChart.chartOptions,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
            ]),
          ]),
        ]),
      ]),
      (_vm.itemBatteryScoreObject.itemBatteryScore ||
        _vm.itemBatteryScoreObject.itemBatteryScore === 0) &&
      _vm.itemBatteryScoreObject.itemBatteryScore !== _vm.INVALID_SCORE
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c(
                      "b-badge",
                      {
                        staticClass: "mb-3",
                        staticStyle: { "font-size": "12px" },
                        attrs: { variant: "primary" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "dataRecordModal.itemBatteryScoreTab.items.title"
                              )
                            )
                        ),
                      ]
                    ),
                    _vm._l(
                      _vm.itemBatteryScoreObject.datapoints,
                      function (dataPoint, dataPointIndex) {
                        return _c(
                          "div",
                          { key: dataPointIndex, staticClass: "row" },
                          [
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("label", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "dataRecordModal.itemBatteryScoreTab.items.text"
                                      )
                                    ) +
                                    " " +
                                    _vm._s(dataPointIndex + 1) +
                                    " "
                                ),
                              ]),
                            ]),
                            dataPoint.itemBatteryScore !== _vm.INVALID_SCORE
                              ? _c("div", { staticClass: "col-md-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        dataPoint.itemBatteryScore.toFixed(3)
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", { staticClass: "col-md-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.general.notAvailable"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                            dataPoint.itemBatteryScore !== _vm.INVALID_SCORE
                              ? _c("div", [
                                  _c("span", [
                                    _c("i", {
                                      staticClass:
                                        "bx bxs-circle text-primary ml-2",
                                      class: _vm.trafficLightClassDecider(
                                        dataPoint.itemBatteryScore
                                      ),
                                      staticStyle: { "font-size": "15px" },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.itemBatteryScoreObject.itemBatteryScore !== _vm.INVALID_SCORE
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c(
                      "b-badge",
                      {
                        staticClass: "mb-3",
                        staticStyle: { "font-size": "12px" },
                        attrs: { variant: "primary" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "dataRecordModal.itemBatteryScoreTab.itemBehaviour.title"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", [
                      _c("div", { staticClass: "row mb-3" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-8" },
                          [
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "dataRecordModal.itemBatteryScoreTab.itemBehaviour.dropdownLabel"
                                    )
                                  ) +
                                  ":   "
                              ),
                            ]),
                            _c("b-form-select", {
                              staticClass: "ml-4 col-md-6",
                              attrs: {
                                size: "sm",
                                options: _vm.itemBatterySelectOptions,
                              },
                              on: { change: _vm.updateSelectedIBS },
                              model: {
                                value: _vm.selectedItemBattery,
                                callback: function ($$v) {
                                  _vm.selectedItemBattery = $$v
                                },
                                expression: "selectedItemBattery",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.itemBatteryLineChartData[0].data.length > 0
                          ? _c("div", { staticClass: "col-md-4 text-right" }, [
                              _c(
                                "div",
                                [
                                  _vm.gqsPattern.pattern !== "" &&
                                  _vm.gqsPattern.pattern !== "Valid"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-silver font-size-12",
                                          class: {
                                            "gqs-pattern-bad":
                                              `${_vm.gqsPattern.pattern}` !=
                                              "Valid",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.gqsPattern.pattern) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      staticClass: "btn-sm ml-3",
                                      attrs: {
                                        title: _vm.isFbButtonDisabled
                                          ? this.$i18n.t(
                                              "dataRecordModal.itemBatteryScoreTab.feedbackModal.fbAlreadySent"
                                            )
                                          : "",
                                        disabled: _vm.isFbButtonDisabled,
                                        variant: "warning",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showGQSFeedbackModal = true
                                        },
                                      },
                                    },
                                    [_c("i", { class: _vm.fbButtonIconClass })]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "dataRecordModal.itemBatteryScoreTab.patternCheck.label"
                                  )
                                ) +
                                ":"
                            ),
                          ]),
                          _vm.isPatternCheckActivatedForSelectedIBS
                            ? _c(
                                "span",
                                { staticClass: "ml-2" },
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { variant: "info" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isPatternCheckActivatedForSelectedIBS
                            ? _c(
                                "span",
                                { staticClass: "ml-2" },
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { variant: "light" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _vm.itemBatteryLineChartData[0].data.length > 0
                          ? _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("apexchart", {
                                  staticClass: "apex-charts mt-3",
                                  attrs: {
                                    type: "line",
                                    dir: "ltr",
                                    height: "350",
                                    series: [..._vm.itemBatteryLineChartData],
                                    options:
                                      _vm.itemBatteryLineChartOptions
                                        .chartOptions,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c(
                                  "b-alert",
                                  { attrs: { show: "", variant: "info" } },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "mdi mdi-alert-circle-outline mr-2",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "dataRecordModal.itemBatteryScoreTab.itemBehaviour.dataNotavailable"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.showGQSFeedbackModal
        ? _c("GQSFeedbackModal", {
            attrs: {
              visible: _vm.showGQSFeedbackModal,
              datapointId: _vm.selectedItemBattery,
            },
            on: {
              close: function ($event) {
                _vm.showGQSFeedbackModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }