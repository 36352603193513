<script>
// /* eslint-disable */
import DatafileResultService from "@/api/services/datafileResult.service.js";

export default {
  props: {
    visible: Boolean,
    datapointId: String
  },
  data() {
    return {
      feedbackType: null,
      feedbackSendSuccess: false,
      feedbackSendError: false,
      patternOptions: ['zigzag', 'stairs', 'peak', 'trough', 'other'],
      selectedPattern: "",
      selectedPatternOther: false
    }
  },
  inject: ['sharedGQSData'],
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if(!value) {
          this.$emit("close");
        }
      }
    },
    translatedPatternOptions() {
      return this.patternOptions.map(option => ({
        text: this.$t(`dataRecordModal.itemBatteryScoreTab.feedbackModal.${option}`),
        value: option
      }));
    }
  },
  methods: {
    async sendFeedback() {
      const { recordId, datafileId } = this.sharedGQSData;
      async function delay(ms) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(), ms);
          });
      }
      // chnage "Grid Queston" back into "Item Battery"
      let dataPoint = this.datapointId.replace("Grid Question", "Item Battery")
      let selectedPattern;
      if (this.feedbackType === "false-negative") selectedPattern = this.selectedPattern; // For false positives, don't send a selected pattern
      try {
        const ret = await DatafileResultService.sendGQSFeedback(
          datafileId, recordId, dataPoint, this.feedbackType, selectedPattern
        );
        if (ret) {
          this.feedbackSendSuccess = true;
          await delay(3000);
          this.$emit("close");
        }
      } catch (error) {
        this.feedbackSendError = true;
        console.error("Failed to submit feedback");
        await delay(3000);
        this.feedbackSendError = false;
      }
    },
    updateSelectedPattern(value) {
      if (value !== "other") {
        this.selectedPattern = value;
        this.selectedPatternOther = false;
      }
      else {
        this.selectedPattern = "";
        this.selectedPatternOther = true;
      }
    }
  }
}
</script>
<template>
  <b-modal
    v-model="showModal"
    :title="this.$i18n.t('dataRecordModal.itemBatteryScoreTab.feedbackModal.title')"
    centered
    hide-footer
  >
    <b-alert v-model="feedbackSendSuccess" class="mt-3" variant="success">
      {{ $t('dataRecordModal.itemBatteryScoreTab.feedbackModal.success') }}
    </b-alert>
    <b-alert v-model="feedbackSendError" class="mt-3" variant="danger">
      {{ $t('dataRecordModal.itemBatteryScoreTab.feedbackModal.error') }}
    </b-alert>    
    <div class="row ml-2 mr-2">
      <p>
        {{ $t('dataRecordModal.itemBatteryScoreTab.feedbackModal.text1') }}
      </p>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <b-form-radio
            v-model="feedbackType"
            value="false-positive"
            name="check-fp"
          >
            {{ $t('dataRecordModal.itemBatteryScoreTab.feedbackModal.fp') }}
          </b-form-radio>
          <b-form-radio
            class="mt-2"
            v-model="feedbackType"
            value="false-negative"
            name="check-fn"
          >
            {{ $t('dataRecordModal.itemBatteryScoreTab.feedbackModal.fn') }}
          </b-form-radio>         
        </div>
        <div v-if="feedbackType == 'false-negative'" class="row mb-2 mt-2">
          <div class="col-md-10">
            <label class="col-md-4">
              {{  $t('dataRecordModal.itemBatteryScoreTab.feedbackModal.pattern') }}
            </label>
            <b-form-select
              @change="updateSelectedPattern"
              class="ml-2 col-md-6"
              size="sm"
              :options="translatedPatternOptions"
            >
            </b-form-select>
          </div>
        </div>
        <div v-if="selectedPatternOther && feedbackType === 'false-negative'" class="row">
          <div class="col-md-8 ml-3">
            <b-form-input v-model="selectedPattern" :placeholder="this.$i18n.t('dataRecordModal.itemBatteryScoreTab.feedbackModal.enterPattern')" class="w-100"></b-form-input>
          </div>
        </div>        
      </div>  
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-button variant="light" @click="showModal = false">
          {{ $t('dataRecordModal.itemBatteryScoreTab.feedbackModal.cancel') }}
        </b-button>
        <b-button class="ml-3" variant="success" @click="sendFeedback()">
          {{ $t('dataRecordModal.itemBatteryScoreTab.feedbackModal.submit') }}
        </b-button>
      </div>
    </div>  
  </b-modal>
</template>