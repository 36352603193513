<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import DatafileResultService from "@/api/services/datafileResult.service";
import { trafficLightClassDecider } from "@/shared/util/chart-helper";
import { INVALID_SCORE } from "@/shared/util/const.js";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers";
import Vue from "vue";
import Gravatar from "vue-gravatar";
import {
CSAnswerComparisonChart,
CScoreChart,
IBSAnswerChart,
ItemBatteryScoreChart,
OpenEndedScoreChart,
PScoreChart,
RScoreChart,
TimeScoreChart,
timeScoreComparisonChart
} from "../../../result-charts";

import { EventBus } from "@/shared/util/event-bus.js";
import CSTab from "./CS-tab";
import IBSTab from "./IBS-tab";
import OESTab from "./OES-tab";
import OverviewTab from "./overview-tab";
import PSTab from "./PS-tab";
import TSTab from "./TS-tab";

Vue.component("v-gravatar", Gravatar);
export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    dataRecord: String,
    datafile: String,
    activatedTab: Number,
    activatedTabName: String,
    includeTimestamp: Boolean,
    includeIBS: Boolean,
    includeCS: Boolean,
    includePS: Boolean,
    includeOES: Boolean
  },
  data() {
    return {
      /******** Data Variables ******/
      trafficLightClassDecider: trafficLightClassDecider,
      rScoreChart: null,
      timeScoreChart: null,
      cScoreChart: null,
      pScoreChart: null,
      showIBSAnswerChart: true,
      showCSAnswerChart: true,
      showPSAnswerChart: true,
      isCleaned: false,
      itemBatterySelectOptions: null,
      CSSelectOptions: null,
      PSSelectOptions: null,
      selectedItemBattery: null,
      selectedCSDatapoint: null,
      selectedPSDatapoint: null,
      dataRecordDetails: [],
      updatedDataRecordDetails: [],
      dataRecordDetailsForDataPoint: null,
      overviewDataObject: {},
      timeScoreDataObject: {},
      itemBatteryScoreObject: {},
      certaintyScoreObject: {},
      predictionScoreObject: {},
      selectedCSDatapointObject: {},
      selectedPSDatapointObject: {},
      openEndedScoreObject: {},
      openEndedScoreChartOptions: null,
      selectedGQSPattern: {
        pattern: "",
        feedback: ""
      },
      timeScoreComparisonChartData: [
        {
          name: "This data record",
          data: []
        },
        {
          name: "Median of other data records",
          data: []
        }
      ],
      ItemBatteryLineChartData: [
        {
          name: "Answer",
          data: []
        }
      ],
      chartDataForCScorePerformanceChart: [
        {
          name: "This Respondent",
          data: []
        },
        {
          name: "∅ Prediction other Respondents",
          data: []
        }
      ],
      chartDataForPScorePerformanceChart: [
        {
          name: "Predicted",
          data: []
        },
        {
          name: "Empirical Value",
          data: []
        }
      ],
      timeScoreComparisonChartOptions: null,
      csAvgPredictionChartOptions: null,
      psAvgPredictionChartOptions: null,
      numberOfQualityScores: 0,
      sharedGQSData: {
        datafileId: this.datafile,
        recordId: this.dataRecord
      },
      isPatternCheckActivatedForSelectedIBS: false,
      /******** Operational Variables *******/
      displayData: false,
      isRespondantOpenError: false,
      respondantOpenErrorMsg: null,
      activatedTabOP: 0
    };
  },
  provide() {
    return {
      sharedGQSData: this.sharedGQSData
    }
  },
  components: {
    OverviewTab,
    PSTab,
    CSTab,
    IBSTab,
    TSTab,
    OESTab
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  watch: {
    async dataRecord(newValue, OldValue) {
      this.displayData = false;
      await this.getDataRecordDetails(true)
      this.displayData = true;
    }
  },
  async mounted() {
    // create chart options
    this.initializeEventBus();
    this.rScoreChart = new RScoreChart();
    this.pScoreChart = new PScoreChart();
    this.timeScoreChart = new TimeScoreChart();
    this.cScoreChart = new CScoreChart();
    this.openEndedScoreChartOptions = new OpenEndedScoreChart();
    this.csAvgPredictionChartOptions = new CSAnswerComparisonChart();
    this.psAvgPredictionChartOptions = new CSAnswerComparisonChart();
    this.itemBatteryScoreChart = new ItemBatteryScoreChart();
    this.timeScoreComparisonChartOptions = new timeScoreComparisonChart();
    this.itemBatteryLineChartOptions = new IBSAnswerChart();
    await this.getDataRecordDetails(true);
    this.displayData = true;
    this.INVALID_SCORE = INVALID_SCORE;
    this.activatedTabOP = this.activatedTab;

    if (this.includeTimestamp) this.numberOfQualityScores += 1;
    if (this.includeIBS) this.numberOfQualityScores += 1;
    if (this.includePS) this.numberOfQualityScores += 1;
    if (this.includeCS) this.numberOfQualityScores += 1;
    if (this.includeOES) this.numberOfQualityScores += 1;
  },
  methods: {
    initializeEventBus() {
      // EventBus
      EventBus.$on("updateRespondentModal", async () => {
        await this.getDataRecordDetails(false);
      });
    },
    async refreshIBSChart() {
      this.showIBSAnswerChart = false;
      var delayPromise = function() {
        return new Promise(resolve => {
          var resolveFunction = function() {
            resolve();
          };
          setTimeout(resolveFunction, 100);
        });
      };
      await delayPromise();
      this.ItemBatteryLineChartData = [...this.ItemBatteryLineChartData];
      this.showIBSAnswerChart = true;
    },
    async refreshCSChart() {
      this.showCSAnswerChart = false;
      var delayPromise = function() {
        return new Promise(resolve => {
          var resolveFunction = function() {
            resolve();
          };
          setTimeout(resolveFunction, 100);
        });
      };
      await delayPromise();
      this.chartDataForCScorePerformanceChart = [
        ...this.chartDataForCScorePerformanceChart
      ];
      this.showCSAnswerChart = true;
    },

    async refreshPSChart() {
      this.showPSAnswerChart = false;
      var delayPromise = function() {
        return new Promise(resolve => {
          var resolveFunction = function() {
            resolve();
          };
          setTimeout(resolveFunction, 100);
        });
      };
      await delayPromise();
      // doing this so the state gets updated and rerendered
      this.chartDataForPScorePerformanceChart = [
        ...this.chartDataForPScorePerformanceChart
      ];
      this.showPSAnswerChart = true;
    },

    updateSelectedIBS(value) {
      // chnage "Grid Queston" back into "Item Battery"
      let valueChangeToIBS = value.replace("Grid Question", "Item Battery")
      this.selectedItemBattery = valueChangeToIBS;

      let selectedItemBatteryObject = this.itemBatteryScoreObject.datapoints.filter(
        ({ dataPointIdentifier }) =>
          this.selectedItemBattery === dataPointIdentifier
      )[0];
      //set gqs pattern
      this.selectedGQSPattern.pattern = selectedItemBatteryObject.matrixPattern;
      this.selectedGQSPattern.feedback = selectedItemBatteryObject.matrixFeedback;

      // reset old state
      this.itemBatteryLineChartOptions.chartOptions.xaxis.categories = [];
      this.ItemBatteryLineChartData[0].data = [];

      //set data for the updated state
      for (
        let i = 0;
        i < selectedItemBatteryObject.itemBatteryAnswers.length;
        i++
      ) {
        this.ItemBatteryLineChartData[0].data.push(
          selectedItemBatteryObject.itemBatteryAnswers[i]
        );

        // TODO: we removed these values and used the default numbers with a bit of formatting
        // this.itemBatteryLineChartOptions.chartOptions.xaxis.categories.push(
        //   "Item " + (i + 1)
        // );
      }
      this.isPatternCheckActivatedForSelectedIBS = selectedItemBatteryObject.enablePatternCheck

      // chnage IBS into GQS
      this.selectedItemBattery = this.selectedItemBattery.replace("Item Battery", "Grid Question")

      //  rerender the chart
      this.refreshIBSChart();
    },

    updateSelectedCS(value) {
      this.selectedCSDatapoint = value;

      this.selectedCSDatapointObject = this.certaintyScoreObject.datapoints.filter(
        ({ datapointIdentifier }) =>
          this.selectedCSDatapoint === datapointIdentifier
      )[0];

      // reset old state
      this.csAvgPredictionChartOptions.chartOptions.xaxis.categories = [];
      this.chartDataForCScorePerformanceChart[0].data = [];
      this.chartDataForCScorePerformanceChart[1].data = [];

      //set data for the updated state
      for (
        let i = 0;
        i < this.selectedCSDatapointObject.averagePredictions.length;
        i++
      ) {
        this.chartDataForCScorePerformanceChart[0].data.push(
          Number(
            this.selectedCSDatapointObject.averagePredictions[
              i
            ].sameAnswer.toFixed(3)
          )
        );
        this.chartDataForCScorePerformanceChart[1].data.push(
          Number(
            this.selectedCSDatapointObject.averagePredictions[
              i
            ].differentAnswer.toFixed(3)
          )
        );
        this.csAvgPredictionChartOptions.chartOptions.xaxis.categories.push(
          "Answer " + (i + 1)
        );
      }

      //  rerender the chart
      this.refreshCSChart();
    },

    updateSelectedPS(value) {
      this.selectedPSDatapoint = value;

      let selectedPSDatapointObject = this.predictionScoreObject.datapoints.filter(
        ({ datapointIdentifier }) =>
          this.selectedPSDatapoint === datapointIdentifier
      )[0];

      // reset old state
      this.psAvgPredictionChartOptions.chartOptions.xaxis.categories = [];
      this.chartDataForPScorePerformanceChart[0].data = [];
      this.chartDataForPScorePerformanceChart[1].data = [];

      //set data for the updated state
      for (let i = 0; i < selectedPSDatapointObject.prediction.length; i++) {
        this.chartDataForPScorePerformanceChart[0].data.push(
          Number(
            selectedPSDatapointObject.prediction[i].predictedValue.toFixed(3)
          )
        );

        this.chartDataForPScorePerformanceChart[1].data.push(
          Number(
            selectedPSDatapointObject.prediction[i].empiricalValue.toFixed(3)
          )
        );

        this.psAvgPredictionChartOptions.chartOptions.xaxis.categories.push(
          selectedPSDatapointObject.prediction[i].name
        );
      }

      //  rerender the chart
      this.refreshPSChart();
    },

    async getDataRecordDetails(initial = false) {
      try {
        this.dataRecordDetails = await DatafileResultService.getDataRecordDetails(
          this.datafile,
          this.dataRecord
        );

        this.overviewDataObject = this.dataRecordDetails[0]; //always 0th is overview

        // Step 1: Remove overview data from array and update the array
        this.updatedDataRecordDetails = this.dataRecordDetails.splice(1);

        //if exists remove timestamp and IBS from the array
        if (this.includeTimestamp) {
          this.timeScoreDataObject = this.updatedDataRecordDetails.filter(
            recordDetails =>
              recordDetails.timeScore === -999 || recordDetails.timeScore >= 0
          )[0];

          // Step 2: Remove time score details and update the array
          this.updatedDataRecordDetails = this.updatedDataRecordDetails.filter(
            recordDetails =>
              !recordDetails.timeScore && recordDetails.timeScore !== 0
          );

          // set chart options & data for timescore comparison chart
          this.timeScoreComparisonChartData[0].data = [];
          this.timeScoreComparisonChartData[1].data = [];
          for (let i = 0; i < this.timeScoreDataObject.datapoints.length; i++) {
            if (
              this.timeScoreDataObject.datapoints[i].respondentTimestamp &&
              this.timeScoreDataObject.datapoints[i].respondentTimestamp >= 0
            ) {
              this.timeScoreComparisonChartData[0].data.push(
                this.timeScoreDataObject.datapoints[
                  i
                ].respondentTimestamp.toFixed(3)
              );
              this.timeScoreComparisonChartData[1].data.push(
                this.timeScoreDataObject.datapoints[i].medianTimestamp.toFixed(
                  3
                )
              );

              if (initial) {
                this.timeScoreComparisonChartOptions.chartOptions.xaxis.categories.push(
                  "Time Point " + (i + 1)
                );
              }
            }
          }
        }

        if (this.includeIBS && initial) {
          this.itemBatteryScoreObject = this.updatedDataRecordDetails.filter(
            recordDetails =>
              recordDetails.itemBatteryScore === -999 ||
              recordDetails.itemBatteryScore >= 0
          )[0];

          // Step 2: Remove item battery details and update the array
          this.updatedDataRecordDetails = this.updatedDataRecordDetails.filter(
            recordDetails =>
              !recordDetails.itemBatteryScore &&
              recordDetails.itemBatteryScore !== 0
          );

          //set values to dropdown
          this.itemBatterySelectOptions = this.itemBatteryScoreObject.datapoints.map(
            ({ dataPointIdentifier }) => dataPointIdentifier 
          );

          if (this.itemBatterySelectOptions.length > 0) {
            this.selectedItemBattery = this.itemBatterySelectOptions[0];

            let selectedItemBatteryObject = this.itemBatteryScoreObject.datapoints.filter(
              ({ dataPointIdentifier }) =>
                this.selectedItemBattery === dataPointIdentifier
            )[0];

            // Set gqs pattern
            this.selectedGQSPattern.pattern = selectedItemBatteryObject.matrixPattern;
            this.selectedGQSPattern.feedback = selectedItemBatteryObject.matrixFeedback;
            // set chart options & data for item battery charts
            for (
              let i = 0;
              i < selectedItemBatteryObject.itemBatteryAnswers.length;
              i++
            ) {
              // TODO: we removed these values and used the default numbers with a bit of formatting
              // this.itemBatteryLineChartOptions.chartOptions.xaxis.categories.push(
              //   "Item " + (i + 1)
              // );

              this.ItemBatteryLineChartData[0].data.push(
                selectedItemBatteryObject.itemBatteryAnswers[i]
              );
            }
            this.isPatternCheckActivatedForSelectedIBS =  selectedItemBatteryObject.enablePatternCheck
          }

          // chnage IBS into GQS
          this.selectedItemBattery = this.selectedItemBattery.replace("Item Battery", "Grid Question")
          this.itemBatterySelectOptions = this.itemBatteryScoreObject.datapoints.map(
            ({dataPointIdentifier}) => dataPointIdentifier = dataPointIdentifier.replace("Item Battery", "Grid Question")
          );
        }

        if (this.includeCS) {
          this.certaintyScoreObject = this.updatedDataRecordDetails.filter(
            recordDetails =>
              recordDetails.CScore === -999 || recordDetails.CScore >= 0
          )[0];

          // Step 2: Remove Certinity score details and update the array
          this.updatedDataRecordDetails = this.updatedDataRecordDetails.filter(
            recordDetails => !recordDetails.CScore && recordDetails.CScore !== 0
          );

          //set values to dropdown
          this.CSSelectOptions = this.certaintyScoreObject.datapoints
            .filter(({ CScore }) => CScore !== INVALID_SCORE)
            .map(({ datapointIdentifier }) => datapointIdentifier);

          // set initial chart options & data for CScore comparison chart
          const validCSDatapoints =
            this.certaintyScoreObject &&
            this.certaintyScoreObject.datapoints.filter(
              ({ CScore }) => CScore !== INVALID_SCORE
            );

          if (validCSDatapoints.length > 0) {
            this.selectedCSDatapointObject = validCSDatapoints[0];

            // TODO: datapointIdentifier should be updated to dataPointIdentifier
            this.selectedCSDatapoint = this.selectedCSDatapointObject.datapointIdentifier;

            // reset old state
            if (initial) {
              this.csAvgPredictionChartOptions.chartOptions.xaxis.categories = [];
              this.chartDataForCScorePerformanceChart[0].data = [];
            }
            this.chartDataForCScorePerformanceChart[1].data = [];
            //set data for the updated state
            for (
              let i = 0;
              i < this.selectedCSDatapointObject.averagePredictions.length;
              i++
            ) {
              this.chartDataForCScorePerformanceChart[1].data.push(
                Number(
                  this.selectedCSDatapointObject.averagePredictions[
                    i
                  ].differentAnswer.toFixed(3)
                )
              );
              if (initial) {
                this.chartDataForCScorePerformanceChart[0].data.push(
                  Number(
                    this.selectedCSDatapointObject.averagePredictions[
                      i
                    ].sameAnswer.toFixed(3)
                  )
                );
                this.csAvgPredictionChartOptions.chartOptions.xaxis.categories.push(
                  "Prediction"
                );
              }
            }
          } else {
            this.selectedCSDatapointObject = null;
          }
        }

        if (this.includePS) {
          this.predictionScoreObject = this.updatedDataRecordDetails.filter(
            recordDetails =>
              recordDetails.predictionScore === -999 ||
              recordDetails.predictionScore >= 0
          )[0];

          // Step 2: Remove prediction score details and update the array
          this.updatedDataRecordDetails = this.updatedDataRecordDetails.filter(
            recordDetails =>
              !recordDetails.predictionScore &&
              recordDetails.predictionScore !== 0
          );

          //set values to dropdown
          this.PSSelectOptions = this.predictionScoreObject.datapoints
            .filter(({ predictionScore }) => predictionScore !== INVALID_SCORE)
            .map(({ datapointIdentifier }) => datapointIdentifier);

          // set initial chart options & data for CScore comparison chart
          const initialPredictionScoreDatapoint =
            this.predictionScoreObject &&
            this.predictionScoreObject.datapoints[0];
          // TODO: datapointIdentifier should be updated to dataPointIdentifier
          this.selectedPSDatapoint =
            initialPredictionScoreDatapoint.datapointIdentifier;

          if (initialPredictionScoreDatapoint) {
            this.chartDataForPScorePerformanceChart[1].data = [];
            for (
              let i = 0;
              i < initialPredictionScoreDatapoint.prediction.length;
              i++
            ) {
              this.chartDataForPScorePerformanceChart[1].data.push(
                Number(
                  initialPredictionScoreDatapoint.prediction[
                    i
                  ].empiricalValue.toFixed(3)
                )
              );
              if (initial) {
                this.psAvgPredictionChartOptions.chartOptions.xaxis.categories.push(
                  initialPredictionScoreDatapoint.prediction[i].name
                );
                this.chartDataForPScorePerformanceChart[0].data.push(
                  Number(
                    initialPredictionScoreDatapoint.prediction[
                      i
                    ].predictedValue.toFixed(3)
                  )
                );
              }
            }
          }
        }
        if (this.includeOES) {
          this.openEndedScoreObject = this.updatedDataRecordDetails.filter(
            recordDetails =>
              recordDetails.oeScore === -999 || recordDetails.oeScore >= 0
          )[0];

          // Step 2: Remove open ended score details and update the array
          this.updatedDataRecordDetails = this.updatedDataRecordDetails.filter(
            recordDetails =>
              !recordDetails.oeScore && recordDetails.oeScore !== 0
          );
        }

        //Step 3: remianing data are the filtered datapoints
        this.dataRecordDetailsForDataPoint = [];
      } catch (error) {
        console.log(error);
        this.isRespondantOpenError = true;
        this.respondantOpenErrorMsg = getErrorMessageByErrorCode(error.message);
      }
    }
  }
};
</script>

<template>
  <!-- Data Record Model -->
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    scrollable
    hide-header-close
    size="xl"
  >
    <template #modal-header v-if="this.displayData">
      <div class="row" style="width: 100vw;">
        <!-- Respondent Personal Details -->
        <div class="col-md-6">
          <div class="media">
            <div class="mr-3">
              <v-gravatar
                :email="String(dataRecord)"
                class="rounded-circle"
                size="45"
              />
            </div>

            <div class="media-body overflow-hidden">
              <h5 class="mb-1 mt-1 font-size-14">
                {{ dataRecord }}
              </h5>
              {{ $t("dataRecordModal.general.dataRecordNumber") }}
            </div>
          </div>
        </div>

        <!-- Respondent Overview Data -->
        <div class="col-md-6 text-right">
          <!-- Respondent Exclued or Include -->
          <b-badge
            style="font-size: 12px"
            variant="danger"
            v-if="!overviewDataObject.isIncluded"
          >
            {{ $t("dataRecordModal.general.excludedBadge") }} -
            <span v-if="overviewDataObject.excludedReason === 'Manually'">
              {{ $t("dataRecordModal.general.excludedReason.manual") }}</span
            >
            <span v-if="overviewDataObject.excludedReason === 'Quota'">
              {{ $t("dataRecordModal.general.excludedReason.quota") }}</span
            >
            <span v-if="overviewDataObject.excludedReason === 'R-Score'">
              {{ $t("dataRecordModal.general.excludedReason.rScore") }}</span
            >
            <span v-if="overviewDataObject.excludedReason === 'CS'">
              {{ $t("dataRecordModal.general.excludedReason.CS") }}</span
            >
            <span v-if="overviewDataObject.excludedReason === 'PS'">
              {{ $t("dataRecordModal.general.excludedReason.PS") }}</span
            >
            <span v-if="overviewDataObject.excludedReason === 'TS'">
              {{ $t("dataRecordModal.general.excludedReason.TS") }}</span
            >
            <span v-if="overviewDataObject.excludedReason === 'IBS'">
              {{ $t("dataRecordModal.general.excludedReason.IBS") }}</span
            >
            <span v-if="overviewDataObject.excludedReason === 'SDS'">
              {{ $t("dataRecordModal.general.excludedReason.SDS") }}</span
            >
            <span v-if="overviewDataObject.excludedReason === 'IS'">
              {{ $t("dataRecordModal.general.excludedReason.IS") }}</span
            >
            <span v-if="overviewDataObject.excludedReason === 'OES'">
              {{ $t("dataRecordModal.general.excludedReason.OES") }}</span
            >
          </b-badge>
          <!-- Traffic Light  -->
          <i
            class="bx bxs-circle text-primary ml-2"
            :class="trafficLightClassDecider(overviewDataObject.datafileRScore)"
            style="font-size: 15px"
          ></i>
        </div>
      </div>
    </template>

    <!-- Display Data -->
    <div v-if="this.displayData">
      <!-- Data Section -->
      <div class="row">
        <!-- Tab -->
        <div class="col-md-12 mb-4" v-if="displayData">
          <b-tabs content-class="pt-3" v-model="activatedTabOP">
            <!-- Overview tab -->
            <b-tab lazy v-if="this.numberOfQualityScores > 1">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="bx bx-notification"></i>
                </span>
                <span class="d-none d-sm-inline-block">
                  {{ $t("dataRecordModal.general.tabs.summary") }}
                </span>
              </template>
              <OverviewTab :overviewDataObject="this.overviewDataObject" />
            </b-tab>

            <!-- SDS and IS doesn't have individual tabs -->

            <!-- OES Tab -->
            <b-tab content-class="pt-3" v-if="this.includeOES" lazy>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="bx bx-notification"></i>
                </span>
                <span class="d-none d-sm-inline-block">
                  {{ $t("dataRecordModal.general.tabs.openEndedScore") }}
                </span>
              </template>
              <OESTab
                lazy
                :openEndedScoreChartOptions="openEndedScoreChartOptions"
                :openEndedScoreObject="openEndedScoreObject"
                :datafileId="datafile"
                :dataRecordId="dataRecord"
              />
            </b-tab>

            <!-- Time Score Tab -->
            <b-tab v-if="this.includeTimestamp" content-class="pt-3" lazy>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="bx bx-notification"></i>
                </span>
                <span class="d-none d-sm-inline-block">
                  {{ $t("dataRecordModal.general.tabs.timeScore") }}
                </span>
              </template>
              <TSTab
                lazy
                :timeScoreDataObject="timeScoreDataObject"
                :timeScoreChart="timeScoreChart"
                :timeScoreComparisonChartData="timeScoreComparisonChartData"
                :timeScoreComparisonChartOptions="
                  timeScoreComparisonChartOptions
                "
              />
            </b-tab>

            <!-- IBS Tab -->
            <b-tab content-class="pt-3" v-if="this.includeIBS" lazy>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="bx bx-notification"></i>
                </span>
                <span class="d-none d-sm-inline-block">
                  {{ $t("dataRecordModal.general.tabs.itemBatteryScore") }}
                </span>
              </template>
              <IBSTab
                lazy
                :itemBatteryScoreChart="itemBatteryScoreChart"
                :itemBatteryLineChartOptions="itemBatteryLineChartOptions"
                :itemBatterySelectOptions="itemBatterySelectOptions"
                :itemBatteryScoreObject="itemBatteryScoreObject"
                :selectedItemBattery="selectedItemBattery"
                :updateSelectedIBS="updateSelectedIBS"
                :itemBatteryLineChartData="ItemBatteryLineChartData"
                :gqsPattern="selectedGQSPattern"
                :isPatternCheckActivatedForSelectedIBS="isPatternCheckActivatedForSelectedIBS"
              />
            </b-tab>

            <!-- Prediction Score Tab -->
            <b-tab v-if="this.includePS" content-class="pt-3" lazy>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="bx bx-notification"></i>
                </span>
                <span class="d-none d-sm-inline-block">
                  {{ $t("dataRecordModal.general.tabs.predictionScore") }}
                </span>
              </template>
              <PSTab
                lazy
                :pScoreChart="pScoreChart"
                :psAvgPredictionChartOptions="psAvgPredictionChartOptions"
                :showPSAnswerChart="showPSAnswerChart"
                :PSSelectOptions="PSSelectOptions"
                :predictionScoreObject="predictionScoreObject"
                :selectedPSDatapoint="selectedPSDatapoint"
                :updateSelectedPS="updateSelectedPS"
                :chartDataForPScorePerformanceChart="
                  chartDataForPScorePerformanceChart
                "
              />
            </b-tab>

            <!-- Certainty Score Tab -->
            <b-tab v-if="this.includeCS" content-class="pt-3" lazy>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="bx bx-notification"></i>
                </span>
                <span class="d-none d-sm-inline-block">
                  {{ $t("dataRecordModal.general.tabs.certaintyScore") }}
                </span>
              </template>
              <CSTab
                lazy
                :cScoreChart="cScoreChart"
                :csAvgPredictionChartOptions="csAvgPredictionChartOptions"
                :selectedCSDatapoint="selectedCSDatapoint"
                :CSSelectOptions="CSSelectOptions"
                :certaintyScoreObject="certaintyScoreObject"
                :selectedCSDatapointObject="selectedCSDatapointObject"
                :updateSelectedCS="updateSelectedCS"
                :chartDataForCScorePerformanceChart="
                  chartDataForCScorePerformanceChart
                "
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <!-- Loading -->
    <div
      class="col-12 text-center p-5"
      v-if="!this.displayData && !isRespondantOpenError"
    >
      <b-spinner
        style="width: 5rem; height: 5rem"
        class="m-2"
        variant="mid"
        role="status"
      ></b-spinner>
    </div>

    <div
      class="col-12 text-center p-5"
      v-if="!this.displayData && isRespondantOpenError"
    >
      <!-- Modal Open Error -->
      <div v-if="isRespondantOpenError" class="d-flex justify-content-center">
        <h5>
          {{ $t("dataRecordModal.general.error.title") }}
        </h5>
      </div>
      <div
        v-if="isRespondantOpenError"
        class="d-flex justify-content-center mb-3"
      >
        <p style="text-align: center">
          {{ $t("dataRecordModal.general.error.text") }}
          <a
            href="mailto:support@redem.io?subject=Probleme beim Hochladen des Respondant Modal"
            >support@redem.io</a
          >.
        </p>
      </div>
      <b-alert v-model="isRespondantOpenError" class="mt-3" variant="danger"
        >{{ respondantOpenErrorMsg }}
      </b-alert>
    </div>
  </b-modal>
</template>
