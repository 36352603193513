var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            this.overviewDataObject.datafileRScore === _vm.INVALID_SCORE
              ? _c("div", { staticClass: "row mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                        _c("i", {
                          staticClass: "mdi mdi-alert-circle-outline mr-2",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("dataRecordModal.general.notDataRecordMsg")
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ])
              : _c(
                  "div",
                  [
                    _c(
                      "b-badge",
                      {
                        staticClass: "mb-3",
                        staticStyle: { "font-size": "12px" },
                        attrs: { variant: "primary" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "dataRecordModal.summaryTab.generalSection.title"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-8" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "dataRecordModal.summaryTab.generalSection.rScore"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          this.overviewDataObject.datafileRScore !==
                          _vm.INVALID_SCORE
                            ? _c("div", { staticClass: "col-md-6" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.overviewDataObject.datafileRScore.toFixed(
                                        3
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("div", { staticClass: "col-md-6" }, [
                                _vm._v("N/A"),
                              ]),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "dataRecordModal.summaryTab.generalSection.quantileRank"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-6" }, [
                            _vm._v(
                              " " +
                                _vm._s(this.overviewDataObject.rankPercentile) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "dataRecordModal.summaryTab.generalSection.validDataPoints"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-6" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.overviewDataObject.validDataPoints
                                ) +
                                " / " +
                                _vm._s(
                                  this.overviewDataObject.totalDataPoints
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        this.overviewDataObject.standardDeviation &&
                        this.overviewDataObject.averageStandardDeviation &&
                        this.overviewDataObject.standardDeviation !==
                          _vm.INVALID_SCORE
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-6" }, [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.summaryTab.generalSection.stdDeviation"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-md-6" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.overviewDataObject.standardDeviation.toFixed(
                                        3
                                      )
                                    ) +
                                    " / " +
                                    _vm._s(
                                      this.overviewDataObject.averageStandardDeviation.toFixed(
                                        3
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      this.overviewDataObject.datafileRScore !==
                      _vm.INVALID_SCORE
                        ? _c(
                            "div",
                            { staticClass: "col-md-4" },
                            [
                              _c("apexchart", {
                                ref: "meanChart",
                                staticClass: "apex-charts apexChartData",
                                attrs: {
                                  type: "radialBar",
                                  height: 200,
                                  dir: "ltr",
                                  series: [
                                    this.overviewDataObject.datafileRScore.toFixed(
                                      3
                                    ),
                                  ],
                                  options: _vm.rScoreChart.chartOptions,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
          ]),
        ]),
      ]),
    ]),
    this.overviewDataObject.datafileRScore !== _vm.INVALID_SCORE
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-8" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "b-badge",
                          {
                            staticClass: "mb-3",
                            staticStyle: { "font-size": "12px" },
                            attrs: { variant: "primary" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "dataRecordModal.summaryTab.qualityScoreSection.title"
                                  )
                                )
                            ),
                          ]
                        ),
                        !isNaN(this.overviewDataObject.OEScore) &&
                        this.overviewDataObject.OEScore !== _vm.INVALID_SCORE
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-4" }, [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.summaryTab.qualityScoreSection.OES"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.overviewDataObject.OEScore.toLocaleString(
                                        "de-De",
                                        { minimumFractionDigits: 3 }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-5" }, [
                                _c("span", [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle text-primary ml-2",
                                    class: _vm.trafficLightClassDecider(
                                      this.overviewDataObject.OEScore
                                    ),
                                    staticStyle: { "font-size": "15px" },
                                  }),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        !isNaN(this.overviewDataObject.timeScore) &&
                        this.overviewDataObject.timeScore !== _vm.INVALID_SCORE
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-4" }, [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.summaryTab.qualityScoreSection.TS"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.overviewDataObject.timeScore.toLocaleString(
                                        "de-De",
                                        { minimumFractionDigits: 3 }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-5" }, [
                                _c("span", [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle text-primary ml-2",
                                    class: _vm.trafficLightClassDecider(
                                      this.overviewDataObject.timeScore
                                    ),
                                    staticStyle: { "font-size": "15px" },
                                  }),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        !isNaN(this.overviewDataObject.itemBatteryScore) &&
                        this.overviewDataObject.itemBatteryScore !==
                          _vm.INVALID_SCORE
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-4" }, [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.summaryTab.qualityScoreSection.IBS"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.overviewDataObject.itemBatteryScore.toLocaleString(
                                        "de-De",
                                        { minimumFractionDigits: 3 }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-5" }, [
                                _c("span", [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle text-primary ml-2",
                                    class: _vm.trafficLightClassDecider(
                                      this.overviewDataObject.itemBatteryScore
                                    ),
                                    staticStyle: { "font-size": "15px" },
                                  }),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        !isNaN(this.overviewDataObject.predictionScore) &&
                        this.overviewDataObject.predictionScore !==
                          _vm.INVALID_SCORE
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-4" }, [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.summaryTab.qualityScoreSection.PS"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.overviewDataObject.predictionScore.toLocaleString(
                                        "de-De",
                                        { minimumFractionDigits: 3 }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-5" }, [
                                _c("span", [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle text-primary ml-2",
                                    class: _vm.trafficLightClassDecider(
                                      this.overviewDataObject.predictionScore
                                    ),
                                    staticStyle: { "font-size": "15px" },
                                  }),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        !isNaN(this.overviewDataObject.CScore) &&
                        this.overviewDataObject.CScore !== _vm.INVALID_SCORE
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-4" }, [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.summaryTab.qualityScoreSection.CS"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-md-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.overviewDataObject.CScore.toLocaleString(
                                        "de-De",
                                        { minimumFractionDigits: 3 }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-5" }, [
                                _c("span", [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle text-primary ml-2",
                                    class: _vm.trafficLightClassDecider(
                                      this.overviewDataObject.CScore
                                    ),
                                    staticStyle: { "font-size": "15px" },
                                  }),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("apexchart", {
                        ref: "RScorePresenattionChart",
                        staticClass: "apex-charts mt-4",
                        attrs: {
                          height: "250",
                          type: "pie",
                          dir: "ltr",
                          series: _vm.getPresentationChartData(
                            this.overviewDataObject.respondentWeights
                          ),
                          options:
                            _vm.rScorePresentationChartOptions.chartOptions,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }