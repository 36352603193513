var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm.openEndedScoreObject.oeScore &&
            _vm.openEndedScoreObject.oeScore === _vm.INVALID_SCORE
              ? _c("div", { staticClass: "row mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                        _c("i", {
                          staticClass: "mdi mdi-alert-circle-outline mr-2",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("dataRecordModal.general.notDataRecordMsg")
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ])
              : _c("div", { staticClass: "row pb-2" }, [
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "dataRecordModal.predictionScoreTab.generalSection.quantileRank"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-7" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.openEndedScoreObject.rankPercentile) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "dataRecordModal.predictionScoreTab.generalSection.validDataPoints"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-7" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.openEndedScoreObject.validDataPoints) +
                            " / " +
                            _vm._s(_vm.openEndedScoreObject.totalDataPoints) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "dataRecordModal.openEndedScoreTab.duplicateCheckActivated"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-7" }, [
                        _vm.openEndedScoreObject.enableDuplicateChecks
                          ? _c(
                              "span",
                              {},
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "info" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.openEndedScoreObject.enableDuplicateChecks
                          ? _c(
                              "span",
                              {},
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "light" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "dataRecordModal.openEndedScoreTab.contextCheckActivated"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-7" }, [
                        _vm.openEndedScoreObject.isEnableWrongTopicCheck
                          ? _c(
                              "span",
                              {},
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "info" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.openEndedScoreObject.isEnableWrongTopicCheck
                          ? _c(
                              "span",
                              {},
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "light" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("apexchart", {
                        ref: "meanChart",
                        staticClass: "apex-charts apexChartData",
                        attrs: {
                          type: "radialBar",
                          height: "150",
                          dir: "ltr",
                          series: [_vm.openEndedScoreObject.oeScore.toFixed(3)],
                          options: _vm.openEndedScoreChartOptions.chartOptions,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
          ]),
        ]),
      ]),
    ]),
    _vm.openEndedScoreObject.oeScore !== null &&
    _vm.openEndedScoreObject.oeScore !== "undefined" &&
    _vm.openEndedScoreObject.oeScore !== _vm.INVALID_SCORE
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c(
                    "b-badge",
                    {
                      staticClass: "mb-3",
                      staticStyle: { "font-size": "12px" },
                      attrs: { variant: "primary" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "dataRecordModal.openEndedScoreTab.openEndedScores"
                            )
                          )
                      ),
                    ]
                  ),
                  _vm._l(
                    _vm.openEndedScoreObject.datapoints,
                    function (datapoint, datapointIndex) {
                      return _c(
                        "div",
                        { key: datapointIndex, staticClass: "row" },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("label", { staticClass: "text-limt" }, [
                              _vm._v(
                                " " +
                                  _vm._s(datapoint.datapointIdentifier) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            datapoint.oeScore === _vm.INVALID_SCORE
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.general.notAvailable"
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        datapoint.oeScore.toLocaleString(
                                          "de-De",
                                          { minimumFractionDigits: 3 }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]),
                          datapoint.oeScore !== _vm.INVALID_SCORE
                            ? _c("div", [
                                _c("span", [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle text-primary ml-2",
                                    class: _vm.trafficLightClassDecider(
                                      datapoint.oeScore
                                    ),
                                    staticStyle: { "font-size": "15px" },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.openEndedScoreObject.oeScore !== null &&
    _vm.openEndedScoreObject.oeScore !== "undefined" &&
    _vm.openEndedScoreObject.oeScore !== _vm.INVALID_SCORE
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c(
                    "b-badge",
                    {
                      staticClass: "mb-4",
                      staticStyle: { "font-size": "12px" },
                      attrs: { variant: "primary" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "dataRecordModal.openEndedScoreTab.datapoints"
                            )
                          )
                      ),
                    ]
                  ),
                  _vm._l(
                    _vm.openEndedScoreObject.datapoints,
                    function (dataPoint, dataPointIndex) {
                      return _c(
                        "div",
                        { key: dataPointIndex, staticClass: "mb-3" },
                        [
                          dataPointIndex !== 0 ? _c("hr") : _vm._e(),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-4" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "dataRecordModal.predictionScoreTab.dataPointSection.label"
                                          )
                                        ) +
                                        ": "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-8" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(dataPoint.datapointIdentifier) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "row mt-3" }, [
                                _c("div", { staticClass: "col-md-4" }, [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.openEndedScoreTab.question"
                                        )
                                      ) + ": "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-8" }, [
                                  _vm._v(_vm._s(dataPoint.questionTitle)),
                                ]),
                              ]),
                              dataPoint.keywords &&
                              dataPoint.keywords.length > 0
                                ? _c("div", { staticClass: "row mt-3" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "dataRecordModal.openEndedScoreTab.keywords"
                                            )
                                          ) + ": "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8" },
                                      _vm._l(
                                        dataPoint.keywords,
                                        function (keyword, keywordIndex) {
                                          return _c(
                                            "span",
                                            { key: keywordIndex },
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "mr-2 mb-1",
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                  attrs: { variant: "success" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(keyword) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "row mt-3" }, [
                                _c("div", { staticClass: "col-md-4" }, [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.openEndedScoreTab.answer"
                                        )
                                      ) + ": "
                                    ),
                                  ]),
                                ]),
                                dataPoint.answer
                                  ? _c("div", { staticClass: "col-md-8" }, [
                                      dataPoint.answer
                                        ? _c("span", [
                                            _vm._v(
                                              " " + _vm._s(dataPoint.answer)
                                            ),
                                          ])
                                        : _c("span", [_vm._v(" - ")]),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "row mt-3" }, [
                                _c("div", { staticClass: "col-md-4" }, [
                                  _c("label", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "dataRecordModal.openEndedScoreTab.answerCategory"
                                          )
                                        ) +
                                        ": "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-md-8" }, [
                                  dataPoint.answerCategory === "-999"
                                    ? _c("span", [_vm._v(" - ")])
                                    : _vm._e(),
                                  dataPoint.answerCategory !== "-999"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-silver font-size-12",
                                          class: {
                                            "oes-answer-cat-va":
                                              `${dataPoint.answerCategory}` ==
                                              1,
                                            "oes-answer-cat-ga":
                                              `${dataPoint.answerCategory}` ==
                                              4,
                                            "oes-answer-cat-pr":
                                              `${dataPoint.answerCategory}` ==
                                              6,
                                            "oes-answer-cat-no":
                                              `${dataPoint.answerCategory}` ==
                                              7,
                                            "oes-answer-cat-dr":
                                              `${dataPoint.answerCategory}` ==
                                              8,
                                            "oes-answer-cat-da":
                                              `${dataPoint.answerCategory}` ==
                                              9,
                                            "oes-answer-cat-wt":
                                              `${dataPoint.answerCategory}` ==
                                              10,
                                            "oes-answer-cat-wl":
                                              `${dataPoint.answerCategory}` ==
                                              11,
                                            "oes-answer-cat-cp":
                                              `${dataPoint.answerCategory}` ==
                                              12,
                                            "oes-answer-cat-ar":
                                              `${dataPoint.answerCategory}` ==
                                              13,
                                            "oes-answer-cat-ai-error":
                                              `${dataPoint.answerCategory}` ==
                                              -2,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.findOESCategory(
                                                  dataPoint.answerCategory
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              dataPoint.answer && dataPoint.answerCategory == 11
                                ? _c("div", { staticClass: "row mt-3" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "dataRecordModal.openEndedScoreTab.nonExpetctedLanguage"
                                            )
                                          ) + ": "
                                        ),
                                      ]),
                                    ]),
                                    _c("span", { staticClass: "col-md-8" }, [
                                      _c("i", {
                                        staticClass: "bx bxs-flag text-danger",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.findLanguageWithCode(
                                              dataPoint.answerDetectedLanguage
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              dataPoint.answer && dataPoint.answerCategory == 8
                                ? _c("div", { staticClass: "row mt-3" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "dataRecordModal.openEndedScoreTab.duplicateWith"
                                            )
                                          ) + ":"
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "col-md-8" },
                                      _vm._l(
                                        _vm.getAllDuplicateRefs(
                                          dataPoint.duplicateReasons
                                        ),
                                        function (
                                          duplicateRef,
                                          duplicateRefIndex
                                        ) {
                                          return _c(
                                            "span",
                                            {
                                              key: duplicateRefIndex,
                                              staticClass:
                                                "mr-1 badge badge-primary font-size-12 oes-answer-cat-dr",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickOnDuplicateRespondent(
                                                    duplicateRef
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(duplicateRef) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                              dataPoint.answer && dataPoint.answerCategory == 9
                                ? _c("div", { staticClass: "row mt-3" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "dataRecordModal.openEndedScoreTab.duplicatePart"
                                            )
                                          ) + ":"
                                        ),
                                      ]),
                                    ]),
                                    _c("span", { staticClass: "col-md-8" }, [
                                      _c("b", [
                                        _vm._v(
                                          " " +
                                            _vm._s(dataPoint.duplicateReasons) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              !dataPoint.answer
                                ? _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12" },
                                      [
                                        _c(
                                          "b-alert",
                                          {
                                            attrs: {
                                              show: "",
                                              variant: "info",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "mdi mdi-alert-circle-outline mr-2",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "dataRecordModal.general.notDataRecordMsg"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }