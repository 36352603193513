import CustomErrorMessage from "@/shared/util/customErrorMessage.js";
import { ApiCall } from "../apiBase";

class CleaningService {
  async cleanDatafile(
    datafileId,
    thresholds,
    targetSampleSize,
    cleaningMethod,
    dontClean = false
  ) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation cleanDatafile($datafileId: ID!, $thresholds: [ScoreThresholdInput], $targetSampleSize: Int, $cleaningMethod: String, $dontClean: Boolean) {
          cleanDatafile (datafileId: $datafileId, thresholds: $thresholds, targetSampleSize: $targetSampleSize, cleaningMethod: $cleaningMethod, dontClean: $dontClean) {
           status
           message
          }
        }
      `,
        variables: {
          datafileId,
          thresholds,
          targetSampleSize,
          cleaningMethod,
          dontClean
        }
      }
    });

    if (result.data.data?.cleanDatafile.status === "SUCCESS") {
      return true;
    } else {
      throw new CustomErrorMessage(
        result?.data?.errors[0]?.extensions?.code,
        result?.data?.errors[0].message
      );
    }
  }
}

export default new CleaningService();
