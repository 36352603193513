var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "lg",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "p-2" },
        [
          _c("div", { staticClass: "row mt-2 align-items-center" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "card-title" }, [
                _c("i", { staticClass: "bx bx-slider mr-2" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("datafileResultGeneral.weightingModal.title")
                    ) +
                    " "
                ),
              ]),
            ]),
            this.notSelectedQuestionsForWeighting.length > 0
              ? _c(
                  "div",
                  { staticClass: "col-6 text-right" },
                  [
                    !this.clickAddNewQuestionButton
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "light" },
                            on: {
                              click: function ($event) {
                                return _vm.clickAddNewQuestion()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "bx bx-plus mr-1" }),
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "datafileResultGeneral.weightingModal.addProjectiveQuestionButton"
                                )
                              ) + " "
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.notSelectedQuestionsForWeighting,
                                searchable: false,
                                "close-on-select": true,
                                "show-labels": false,
                                placeholder: this.$t(
                                  "datafileResultGeneral.weightingModal.selectProjectiveQuestionPlacehoder"
                                ),
                                "track-by": "dataPointIdentifier",
                                label: "dataPointIdentifier",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.selectProjectiveQuestion()
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "singleLabel",
                                    fn: function ({ option }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(option.dataPointIdentifier) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                166456439
                              ),
                              model: {
                                value: _vm.selectedQuestion,
                                callback: function ($$v) {
                                  _vm.selectedQuestion = $$v
                                },
                                expression: "selectedQuestion",
                              },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "row mt-4 mb-2" }, [
            _c("p", { staticClass: "col-md-12 mb-4" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("datafileResultGeneral.weightingModal.text")) +
                  " "
              ),
            ]),
          ]),
          _c(
            "b-alert",
            {
              staticClass: "ml-2 col-md-11",
              attrs: {
                show: _vm.weightingSuccess,
                dismissible: "",
                variant: "success",
              },
              on: {
                dismissed: function ($event) {
                  _vm.dismissCountDown = 0
                },
                "dismiss-count-down": _vm.countDownChanged,
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("datafileResultGeneral.weightingModal.successMsg")
                )
              ),
            ]
          ),
          _c(
            "b-alert",
            {
              staticClass: "ml-2 col-md-11",
              attrs: {
                show: _vm.weightingFailed,
                dismissible: "",
                variant: "danger",
              },
              on: {
                dismissed: function ($event) {
                  _vm.dismissCountDown = 0
                },
                "dismiss-count-down": _vm.countDownChanged,
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("datafileResultGeneral.weightingModal.errorMsg"))
              ),
            ]
          ),
          !_vm.isLoading && !_vm.weightingSuccess
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-tabs",
                      { staticStyle: { height: "250px" } },
                      [
                        _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("span", {}, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "datafileResultGeneral.weightingModal.dataFileTabTitle"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2358466611
                            ),
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-3 row ml-1" },
                              _vm._l(
                                this.datafileWeightingSettings,
                                function (algorithm, algorithmIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: algorithmIndex,
                                      staticClass: "col-md-12",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-control custom-checkbox mb-3",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: algorithm.value,
                                                expression: "algorithm.value",
                                              },
                                            ],
                                            staticClass: "custom-control-input",
                                            attrs: {
                                              type: "checkbox",
                                              id: algorithmIndex,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                algorithm.value
                                              )
                                                ? _vm._i(
                                                    algorithm.value,
                                                    null
                                                  ) > -1
                                                : algorithm.value,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = algorithm.value,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        algorithm,
                                                        "value",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        algorithm,
                                                        "value",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    algorithm,
                                                    "value",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "custom-control-label",
                                              attrs: { for: algorithmIndex },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-normal",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        `${algorithm.name} (${algorithm.key})`
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                        _vm._l(
                          this.selectedQuestionForWeighting,
                          function (question, questionIndex) {
                            return _c(
                              "b-tab",
                              {
                                key: questionIndex,
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c("span", {}, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  question.dataPointIdentifier
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("div", { staticClass: "mt-3 row ml-1" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-8" },
                                    _vm._l(
                                      question.algorithms,
                                      function (
                                        qualityScore,
                                        qualityScoreIndex
                                      ) {
                                        return _c(
                                          "div",
                                          { key: qualityScoreIndex },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "custom-control custom-checkbox mb-3",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        question.algorithms[
                                                          qualityScoreIndex
                                                        ].value,
                                                      expression:
                                                        "question.algorithms[qualityScoreIndex].value",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "custom-control-input",
                                                  attrs: {
                                                    type: "checkbox",
                                                    id:
                                                      questionIndex +
                                                      qualityScoreIndex +
                                                      qualityScore.name,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      question.algorithms[
                                                        qualityScoreIndex
                                                      ].value
                                                    )
                                                      ? _vm._i(
                                                          question.algorithms[
                                                            qualityScoreIndex
                                                          ].value,
                                                          null
                                                        ) > -1
                                                      : question.algorithms[
                                                          qualityScoreIndex
                                                        ].value,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          question.algorithms[
                                                            qualityScoreIndex
                                                          ].value,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              question
                                                                .algorithms[
                                                                qualityScoreIndex
                                                              ],
                                                              "value",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              question
                                                                .algorithms[
                                                                qualityScoreIndex
                                                              ],
                                                              "value",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          question.algorithms[
                                                            qualityScoreIndex
                                                          ],
                                                          "value",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "custom-control-label",
                                                    attrs: {
                                                      for:
                                                        questionIndex +
                                                        qualityScoreIndex +
                                                        qualityScore.name,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-normal",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              `${qualityScore.name} (${qualityScore.key})`
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c("div", { staticClass: "col-md-4 pr-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "float-right" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "btn-sm",
                                            attrs: { variant: "danger" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeProjectiveQuestion(
                                                  questionIndex
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "datafileResultGeneral.weightingModal.removeProjectiveQuestionButton"
                                                )
                                              ) + " "
                                            ),
                                            _c("i", { staticClass: "bx bx-x" }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 mt-5" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { variant: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.clickApplyWeighting()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-save" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.weightingModal.applyButton"
                              )
                            )
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.clickApplyReset()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-reset" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.weightingModal.resetButton"
                              )
                            )
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "light" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleModal()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-x" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.weightingModal.cancelButton"
                              )
                            )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _c("div", { staticClass: "row justify-content-center" }, [
                _c(
                  "div",
                  { staticClass: "col-6 text-center p-5" },
                  [
                    _c("b-spinner", {
                      staticClass: "m-2",
                      staticStyle: { width: "5rem", height: "5rem" },
                      attrs: { variant: "mid", role: "status" },
                    }),
                  ],
                  1
                ),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }