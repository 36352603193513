import { ApiCall } from "../apiBase";
import { ERROR_CODES } from "@/shared/util/errorCodes.js";
import CustomErrorMessage from "@/shared/util/customErrorMessage.js";
class QuotaService {
  async getTargetQuotaPercentage(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getTargetQuotaPercentage($datafileID: ID!) {
            getTargetQuotaPercentage(datafileId: $datafileID)
          }
        `,
        variables: {
          datafileID: datafileId
        }
      }
    });

    if (
      result?.data?.data?.getTargetQuotaPercentage &&
      !result?.data?.errors
    ) {
      return result.data.data.getTargetQuotaPercentage;
    } else if (
      result?.data?.errors[0]?.extensions?.code ===
      ERROR_CODES.QUOTA_SETTINGS_NOT_SAVED
    ) {
      return result?.data?.errors[0]?.extensions?.code;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  async getQuotaSettings(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getQuotaSettings($datafileId: ID!){
            getQuotaSettings(datafileId: $datafileId){
              originalColumnName
              renamedColumnName
              quotaOptions {
                originalName
                name
                targetValue
                calculatedValue
                isEnabled
              }
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.getQuotaSettings && !result?.data?.errors) {
      return result.data.data.getQuotaSettings;
    } else {
      throw new CustomErrorMessage(
        result?.data?.errors[0]?.extensions?.code,
        result?.data?.errors[0].message
      );
    }
  }

  async getDisplayQuotaTarget(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getShouldDisplayQuotaCleaning($datafileId: ID!){
            getShouldDisplayQuotaCleaning(datafileId: $datafileId)
          }
        `,
        variables: {
          datafileId
        }
      }
    });
    if (
      result?.data?.data?.getShouldDisplayQuotaCleaning ||
      (result?.data?.data?.getShouldDisplayQuotaCleaning === 0 &&
        !result?.data?.errors)
    ) {
      return result.data.data.getShouldDisplayQuotaCleaning;
    } else {
      throw new CustomErrorMessage(
        result?.data?.errors[0]?.extensions?.code,
        result?.data?.errors[0].message
      );
    }
  }

  async saveQuotaSettings(datafileId, quotaSettings) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation saveQuotaSettings($datafileId: ID!, $quotaSettings: [QuotaSettingsInput]!) {
            saveQuotaSettings(datafileId: $datafileId, quotaSettings: $quotaSettings) {
              status
              message
            }
          }
        `,
        variables: {
          datafileId: datafileId,
          quotaSettings: quotaSettings
        }
      }
    });

    if (response?.data?.data?.saveQuotaSettings && !response?.data?.errors) {
      return response.data.data.saveQuotaSettings;
    } else {
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }
}

export default new QuotaService();
