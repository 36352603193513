var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm.predictionScoreObject.predictionScore &&
            _vm.predictionScoreObject.predictionScore === _vm.INVALID_SCORE
              ? _c("div", { staticClass: "row mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                        _c("i", {
                          staticClass: "mdi mdi-alert-circle-outline mr-2",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("dataRecordModal.general.notDataRecordMsg")
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ])
              : _c("div", { staticClass: "row pb-2" }, [
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "dataRecordModal.predictionScoreTab.generalSection.quantileRank"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-8" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.predictionScoreObject.rankPercentile) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "dataRecordModal.predictionScoreTab.generalSection.validDataPoints"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-8" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.predictionScoreObject.validDataPoints) +
                            " / " +
                            _vm._s(_vm.predictionScoreObject.totalDataPoints) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("apexchart", {
                        ref: "meanChart",
                        staticClass: "apex-charts apexChartData",
                        attrs: {
                          type: "radialBar",
                          height: "150",
                          dir: "ltr",
                          series: [
                            _vm.predictionScoreObject.predictionScore.toFixed(
                              3
                            ),
                          ],
                          options: _vm.pScoreChart.chartOptions,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
          ]),
        ]),
      ]),
    ]),
    (_vm.predictionScoreObject.predictionScore ||
      _vm.predictionScoreObject.predictionScore === 0) &&
    _vm.predictionScoreObject.predictionScore !== _vm.INVALID_SCORE
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c(
                    "b-badge",
                    {
                      staticClass: "mb-3",
                      staticStyle: { "font-size": "12px" },
                      attrs: { variant: "primary" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "dataRecordModal.predictionScoreTab.dataPointSection.title"
                            )
                          )
                      ),
                    ]
                  ),
                  _vm._l(
                    _vm.predictionScoreObject.datapoints,
                    function (datapoint, datapointIndex) {
                      return _c(
                        "div",
                        { key: datapointIndex, staticClass: "row" },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "dataRecordModal.predictionScoreTab.dataPointSection.label"
                                    )
                                  ) +
                                  " " +
                                  _vm._s(datapointIndex + 1) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-2" }, [
                            datapoint.predictionScore === _vm.INVALID_SCORE
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "dataRecordModal.general.notAvailable"
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        datapoint.predictionScore.toLocaleString(
                                          "de-De",
                                          { minimumFractionDigits: 3 }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]),
                          datapoint.predictionScore !== _vm.INVALID_SCORE
                            ? _c("div", [
                                _c("span", [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle text-primary ml-2",
                                    class: _vm.trafficLightClassDecider(
                                      datapoint.predictionScore
                                    ),
                                    staticStyle: { "font-size": "15px" },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.predictionScoreObject.predictionScore !== _vm.INVALID_SCORE
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c(
                    "b-badge",
                    {
                      staticClass: "mb-3",
                      staticStyle: { "font-size": "12px" },
                      attrs: { variant: "primary" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "dataRecordModal.predictionScoreTab.predictionChart.title"
                            )
                          )
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "dataRecordModal.predictionScoreTab.predictionChart.dropdownLabel"
                                  )
                                ) +
                                ":   "
                            ),
                          ]),
                          _c("b-form-select", {
                            staticClass: "ml-4 col-md-4",
                            attrs: { size: "sm", options: _vm.PSSelectOptions },
                            on: { change: _vm.updateSelectedPS },
                            model: {
                              value: _vm.selectedPSDatapoint,
                              callback: function ($$v) {
                                _vm.selectedPSDatapoint = $$v
                              },
                              expression: "selectedPSDatapoint",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("apexchart", {
                        ref: "performanceChart2",
                        staticClass: "apex-charts mt-3 mb-3",
                        attrs: {
                          dir: "ltr",
                          type: "bar",
                          height: "300",
                          series: [..._vm.chartDataForPScorePerformanceChart],
                          options: {
                            ..._vm.psAvgPredictionChartOptions.chartOptions,
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }