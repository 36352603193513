var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "p-2" }, [
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "row mt-0 text-left" },
              [
                _c("div", { staticClass: "col-12" }, [
                  _c("h5", { staticClass: "mb-4" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("datafileResultGeneral.exportModal.title")
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c(
                  "b-alert",
                  {
                    staticClass: "ml-2 col-md-11",
                    attrs: {
                      show: _vm.dismissCountDown,
                      dismissible: "",
                      variant: "danger",
                    },
                    on: {
                      dismissed: function ($event) {
                        _vm.dismissCountDown = 0
                      },
                      "dismiss-count-down": _vm.countDownChanged,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("datafileResultGeneral.exportModal.errorMsg")
                      )
                    ),
                  ]
                ),
                _c("div", { staticClass: "col-12 ml-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.onlyIncludedRecords,
                        expression: "onlyIncludedRecords",
                      },
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "radio", checked: "" },
                    domProps: {
                      value: 0,
                      checked: _vm._q(_vm.onlyIncludedRecords, 0),
                    },
                    on: {
                      change: function ($event) {
                        _vm.onlyIncludedRecords = 0
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "exampleRadios1" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "datafileResultGeneral.exportModal.exportOption1"
                          )
                        )
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "col-12 ml-4 mt-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.onlyIncludedRecords,
                        expression: "onlyIncludedRecords",
                      },
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "radio", checked: "" },
                    domProps: {
                      value: 1,
                      checked: _vm._q(_vm.onlyIncludedRecords, 1),
                    },
                    on: {
                      change: function ($event) {
                        _vm.onlyIncludedRecords = 1
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "exampleRadios1" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "datafileResultGeneral.exportModal.exportOption2"
                          )
                        )
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "col-12 ml-4 mt-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.onlyIncludedRecords,
                        expression: "onlyIncludedRecords",
                      },
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "radio", checked: "" },
                    domProps: {
                      value: 2,
                      checked: _vm._q(_vm.onlyIncludedRecords, 2),
                    },
                    on: {
                      change: function ($event) {
                        _vm.onlyIncludedRecords = 2
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "exampleRadios1" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "datafileResultGeneral.exportModal.exportOption3"
                          )
                        )
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12 mt-4" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { variant: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.clickExport()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-export" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.exportModal.exportButton"
                              )
                            )
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "light" },
                        on: {
                          click: function ($event) {
                            return _vm.clickCancel()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-x" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafileResultGeneral.exportModal.cancelButton"
                              )
                            )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-12 text-center p-2" }, [
                _c("div", { staticClass: "mt-3" }, [
                  _c("i", {
                    staticClass:
                      "bx bx-download bx-flashing display-2 text-primary",
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-5 d-flex justify-content-center" },
                  [
                    _c("h5", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultGeneral.exportModal.downloading.title"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center mb-3" },
                  [
                    _c("p", { staticStyle: { "text-align": "center" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "datafileResultGeneral.exportModal.downloading.description"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }