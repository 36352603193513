<script>
import WorksheetService from "@/api/services/worksheet.service.js";
// import DatafileReportService from '@/api/services/datafileReport.service';

export default {
  props: {
    visible: Boolean,
    datafileId: String,
    isLiveCleaning: String
  },
  data() {
    return {
      /******** Data Variables ******/
      onlyIncludedRecords: 0,
      allRecords: false,
      isLoading: false,
      dismissSecs: 3,
      dismissCountDown: 0
      /******** Operational Variables *******/
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    async clickExport() {
      this.isLoading = true;
      try {
        const destinationURL = await WorksheetService.exportWorkbook(
          this.datafileId,
          this.onlyIncludedRecords,
          this.$i18n.locale
        );
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = destinationURL;
        document.body.appendChild(link);
        // Programmatically click the link to trigger the download
        link.click();
        // Clean up by removing the anchor element
        document.body.removeChild(link);
        this.$emit("close");
      } catch (e) {
        this.isLoading = false;
        this.dismissCountDown = this.dismissSecs;
      }
      this.isLoading = false;
    },
    clickCancel() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="p-2">
      <!-- Header and input for selecting export options -->
      <div class="row mt-0 text-left" v-if="!isLoading">
        <div class="col-12">
          <h5 class="mb-4">
            {{ $t("datafileResultGeneral.exportModal.title") }}
          </h5>
        </div>

        <!-- API Error --->
        <b-alert
          :show="dismissCountDown"
          dismissible
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
          class="ml-2 col-md-11"
          variant="danger"
          >{{ $t("datafileResultGeneral.exportModal.errorMsg") }}</b-alert
        >

        <div class="col-12 ml-4">
          <input
            v-model="onlyIncludedRecords"
            class="form-check-input"
            type="radio"
            :value="0"
            checked
          />
          <label class="form-check-label" for="exampleRadios1">{{
            $t("datafileResultGeneral.exportModal.exportOption1")
          }}</label>
        </div>
        <div class="col-12 ml-4 mt-2">
          <input
            v-model="onlyIncludedRecords"
            class="form-check-input"
            type="radio"
            :value="1"
            checked
          />
          <label class="form-check-label" for="exampleRadios1">{{
            $t("datafileResultGeneral.exportModal.exportOption2")
          }}</label>
        </div>
        <div class="col-12 ml-4 mt-2">
          <input
            v-model="onlyIncludedRecords"
            class="form-check-input"
            type="radio"
            :value="2"
            checked
          />
          <label class="form-check-label" for="exampleRadios1">{{
            $t("datafileResultGeneral.exportModal.exportOption3")
          }}</label>
        </div>

        <!-- Actions -->
        <div class="col-12 mt-4">
          <b-button @click="clickExport()" variant="success" class="mr-2"
            ><i class="bx bx-export"></i>
            {{ $t("datafileResultGeneral.exportModal.exportButton") }}</b-button
          >
          <b-button @click="clickCancel()" variant="light"
            ><i class="bx bx-x"></i>
            {{ $t("datafileResultGeneral.exportModal.cancelButton") }}</b-button
          >
        </div>
      </div>

      <!-- Loading until export file is downloaded --->
      <div class="row justify-content-center" v-else>
        <div class="col-12 text-center p-2">
          <div class="mt-3">
            <i class="bx bx-download bx-flashing display-2 text-primary"></i>
          </div>
          <div class="mt-5 d-flex justify-content-center">
            <h5>
              {{ $t("datafileResultGeneral.exportModal.downloading.title") }}
            </h5>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <p style="text-align:center">
              {{
                $t("datafileResultGeneral.exportModal.downloading.description")
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
