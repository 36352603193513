import { ApiCall } from "../apiBase";
import CustomErrorMessage from "../../shared/util/customErrorMessage.js";

class WorksheetService {
  // get worksheet by Id
  async getWorksheet(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getWorksheet($datafileId: ID!) {
            getWorksheet(datafileId: $datafileId) {
              datafileId
              worksheet
              headerRow
              settings
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.getWorksheet && !result?.data?.errors) {
      return result.data.data.getWorksheet;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  // update worksheet by using Id
  async updateWorksheet(datafileId, editedCells, updatedWorksheet, updatedHeaderRow, updatedSettings) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateWorksheet($input: WorksheetUpdate) {
            updateWorksheet(worksheetUpdate: $input) {
              status
              message
            }
          }
        `,
        variables: {
          input: {
            datafileId: datafileId,
            editedCells,
            updatedWorksheet,
            updatedHeaderRow,
            updatedSettings
          }
        }
      }
    });

    if (response?.data?.data?.updateWorksheet && !response?.data?.errors) {
      return response.data.data.updateWorksheet;
    } else {
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }

  async updateWorksheetWithNewRecords(datafileId, newCredits, cleanDatafile) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateWorksheetWithNewRecords($datafileId: ID!, $newCredits: Float!, $cleanDatafile: Boolean) {
            updateWorksheetWithNewRecords(datafileId: $datafileId, newCredits: $newCredits, cleanDatafile: $cleanDatafile) {
              status
              message
            }
          }
        `,
        variables: {
          datafileId,
          newCredits,
          cleanDatafile
        }
      }
    });

    if (
      response?.data?.data?.updateWorksheetWithNewRecords &&
      !response?.data?.errors
    ) {
      return response.data.data.updateWorksheetWithNewRecords;
    } else {
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }

  async validateWorksheetWithNewRecords(datafileId, headerRow) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation validateWorksheetWithNewRecords($input: WorksheetInput!) {
            validateWorksheetWithNewRecords(worksheetInput: $input) {
              totalDatarecords
              newDatarecords
              newCredits
            }
          }
        `,
        variables: {
          input: {
            datafileId: datafileId,
            headerRow: headerRow
          }
        }
      }
    });

    if (
      response?.data?.data?.validateWorksheetWithNewRecords &&
      !response?.data?.errors
    ) {
      return response.data.data.validateWorksheetWithNewRecords;
    } else {
      throw new CustomErrorMessage(
				response?.data?.errors[0]?.extensions?.code,
				response?.data?.errors[0].message
			);
    }
  }

  async exportWorksheet(datafileId, onlyIncludedRespondents) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query exportWorksheet($datafileId: ID!, $onlyIncludedRespondents: Int) {
            exportWorksheet(datafileId: $datafileId, onlyIncludedRespondents: $onlyIncludedRespondents) {
              headers
              rows {
                ... on NumberType {
                  Number
                }
                ... on TextType {
                  Text
                }
              }
            }
          }
        `,
        variables: {
          datafileId,
          onlyIncludedRespondents
        }
      }
    });

    if (response?.data?.data?.exportWorksheet && !response?.data?.errors) {
      return response.data.data.exportWorksheet;
    } else {
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }

  async exportWorkbook(datafileId, onlyIncludedRespondents, lang) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query exportWorkbook($datafileId: ID!, $onlyIncludedRespondents: Int, $lang: String) {
            exportWorkbook(datafileId: $datafileId, onlyIncludedRespondents: $onlyIncludedRespondents, lang: $lang)
          }
        `,
        variables: {
          datafileId,
          onlyIncludedRespondents,
          lang
        }
      }
    });

    if (response?.data?.data?.exportWorkbook && !response?.data?.errors) {
      return response.data.data.exportWorkbook;
    } else {
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }  

  async extractHeaderRow(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getHeaderRow($datafileId: ID!){
            getHeaderRow(datafileId: $datafileId)
          }
        `,
        variables: {
          datafileId: datafileId,
        }
      }
    });

    if (
      result?.data?.data?.getHeaderRow &&
      !result?.data?.errors
    ) {
      return result.data.data.getHeaderRow;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  async extractAnswerOptions(datafileId, columnName) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAllAnswerOptionsByColumnName($datafileId: ID!, $columnName: String!){
            getAllAnswerOptionsByColumnName(datafileId: $datafileId, columnName: $columnName)
          }
        `,
        variables: {
          datafileId: datafileId,
          columnName: columnName
        }
      }
    });

    if (
      result?.data?.data?.getAllAnswerOptionsByColumnName &&
      !result?.data?.errors
    ) {
      return result.data.data.getAllAnswerOptionsByColumnName;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }
}

export default new WorksheetService();
