<script>
import Multiselect from "vue-multiselect";
import WeightingService from "@/api/services/weighting.service";

export default {
  components: {
    Multiselect
  },
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileId: String,
    weightingSettings: Object
  },
  data() {
    return {
      /******** Data Variables ******/
      duplicateWeightingSettingSettings: null,
      datafileWeightingSettings: null,
      questionsWeightingSettings: null,
      selectedQuestionForWeighting: [],
      notSelectedQuestionsForWeighting: [],
      selectedQuestion: null,
      /***** Operational Varible *****/
      weightingSuccess: 0,
      weightingFailed: 0,
      isLoading: false,
      dismissSecs: 3,
      dismissCountDown: null,
      clickAddNewQuestionButton: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async mounted() {
    if (this.weightingSettings) {
      await this.setData();
    }
  },
  methods: {
    setData() {
      this.duplicateWeightingSettingSettings = this.weightingSettings;
      this.datafileWeightingSettings = this.weightingSettings.datafileWeighting;
      this.questionsWeightingSettings =
        this.weightingSettings.projectiveQuestionsWeighting;

      this.questionsWeightingSettings.forEach((question) => {
        if (question.apply) {
          this.selectedQuestionForWeighting.push(question);
        } else {
          this.notSelectedQuestionsForWeighting.push(question);
        }
      });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    async clickApplyWeighting() {
      this.isLoading = true;

      let questionsWeightingSettingsToPass = [
        ...this.selectedQuestionForWeighting,
        ...this.notSelectedQuestionsForWeighting
      ];

      let weightingSettingsToPass = {
        datafileWeighting: this.datafileWeightingSettings,
        projectiveQuestionsWeighting: questionsWeightingSettingsToPass
      };

      try {
        const response = await WeightingService.weightDatafile(
          this.datafileId,
          weightingSettingsToPass
        );

        if (response) {
          this.weightingSuccess = this.dismissSecs;
          this.isLoading = false;
          this.$emit("reload");
        }
      } catch (error) {
        console.log("clickApplyWeighting: ", error.message);
        this.isLoading = false;
        this.weightingFailed = this.dismissSecs;
      }
    },
    async clickApplyReset() {
      this.isLoading = true;

      try {
        const response = await WeightingService.resetDatafile(this.datafileId);

        if (response) {
          this.weightingSuccess = this.dismissSecs;
          this.isLoading = false;
          this.$emit("reload");
        }
      } catch (error) {
        console.log("clickApplyReset: ", error.message);
        this.isLoading = false;
        this.weightingFailed = this.dismissSecs;
      }
    },
    toggleModal() {
      this.showModal = false;
    },
    clickAddNewQuestion() {
      this.clickAddNewQuestionButton = true;
    },
    selectProjectiveQuestion() {
      // remove selected question from notUsedProjectiveQuestions
      for (let i = 0; i < this.notSelectedQuestionsForWeighting.length; i++) {
        if (
          this.selectedQuestion.dataPointIdentifier ===
          this.notSelectedQuestionsForWeighting[i].dataPointIdentifier
        ) {
          this.notSelectedQuestionsForWeighting[i].apply = true;
          this.selectedQuestionForWeighting.push(
            this.notSelectedQuestionsForWeighting[i]
          );
          this.notSelectedQuestionsForWeighting.splice(i, 1);
        }
      }

      // get component state to initial state
      this.selectedQuestion = null;
      this.clickAddNewQuestionButton = false;
    },
    removeProjectiveQuestion(questionIndex) {
      this.selectedQuestionForWeighting[questionIndex].apply = false;

      this.notSelectedQuestionsForWeighting.push(
        this.selectedQuestionForWeighting[questionIndex]
      );

      this.selectedQuestionForWeighting.splice(questionIndex, 1);
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="lg"
  >
    <div class="p-2">
      <!-- Header -->
      <div class="row mt-2 align-items-center">
        <div class="col-6">
          <div class="card-title">
            <i class="bx bx-slider mr-2"></i>
            {{ $t("datafileResultGeneral.weightingModal.title") }}
          </div>
        </div>
        <div
          class="col-6 text-right"
          v-if="this.notSelectedQuestionsForWeighting.length > 0"
        >
          <b-button
            v-if="!this.clickAddNewQuestionButton"
            variant="light"
            @click="clickAddNewQuestion()"
          >
            <i class="bx bx-plus mr-1"></i
            >{{
              $t(
                "datafileResultGeneral.weightingModal.addProjectiveQuestionButton"
              )
            }}
          </b-button>
          <div v-else>
            <multiselect
              v-model="selectedQuestion"
              :options="notSelectedQuestionsForWeighting"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :placeholder="
                this.$t(
                  'datafileResultGeneral.weightingModal.selectProjectiveQuestionPlacehoder'
                )
              "
              @input="selectProjectiveQuestion()"
              track-by="dataPointIdentifier"
              label="dataPointIdentifier"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.dataPointIdentifier }}
              </template></multiselect
            >
          </div>
        </div>
      </div>

      <div class="row mt-4 mb-2">
        <p class="col-md-12 mb-4">
          {{ $t("datafileResultGeneral.weightingModal.text") }}
        </p>
      </div>

      <b-alert
        :show="weightingSuccess"
        @dismissed="dismissCountDown = 0"
        dismissible
        @dismiss-count-down="countDownChanged"
        class="ml-2 col-md-11"
        variant="success"
        >{{ $t("datafileResultGeneral.weightingModal.successMsg") }}</b-alert
      >

      <b-alert
        :show="weightingFailed"
        @dismissed="dismissCountDown = 0"
        dismissible
        @dismiss-count-down="countDownChanged"
        class="ml-2 col-md-11"
        variant="danger"
        >{{ $t("datafileResultGeneral.weightingModal.errorMsg") }}</b-alert
      >

      <div class="row" v-if="!isLoading && !weightingSuccess">
        <!-- Tabs -->
        <div class="col-md-12">
          <b-tabs style="height: 250px">
            <!-- Datafile Tab -->
            <b-tab>
              <template v-slot:title>
                <span class="">
                  {{
                    $t("datafileResultGeneral.weightingModal.dataFileTabTitle")
                  }}
                </span>
              </template>
              <div class="mt-3 row ml-1">
                <div
                  class="col-md-12"
                  v-for="(algorithm, algorithmIndex) in this
                    .datafileWeightingSettings"
                  :key="algorithmIndex"
                >
                  <div class="custom-control custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="algorithmIndex"
                      v-model="algorithm.value"
                    />
                    <label class="custom-control-label" :for="algorithmIndex">
                      <span class="font-weight-normal">
                        {{ `${algorithm.name} (${algorithm.key})` }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </b-tab>
            <!-- Projective Questions tabs -->
            <b-tab
              v-for="(question, questionIndex) in this
                .selectedQuestionForWeighting"
              :key="questionIndex"
            >
              <template v-slot:title>
                <span class="">
                  {{ question.dataPointIdentifier }}
                </span>
              </template>
              <div class="mt-3 row ml-1">
                <div class="col-md-8">
                  <div
                    v-for="(
                      qualityScore, qualityScoreIndex
                    ) in question.algorithms"
                    :key="qualityScoreIndex"
                  >
                    <div class="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="
                          questionIndex + qualityScoreIndex + qualityScore.name
                        "
                        v-model="question.algorithms[qualityScoreIndex].value"
                      />
                      <label
                        class="custom-control-label"
                        :for="
                          questionIndex + qualityScoreIndex + qualityScore.name
                        "
                      >
                        <span class="font-weight-normal">
                          {{ `${qualityScore.name} (${qualityScore.key})` }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 pr-4">
                  <div class="float-right">
                    <b-button
                      class="btn-sm"
                      variant="danger"
                      @click="removeProjectiveQuestion(questionIndex)"
                      >{{
                        $t(
                          "datafileResultGeneral.weightingModal.removeProjectiveQuestionButton"
                        )
                      }}
                      <i class="bx bx-x"></i
                    ></b-button>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>

        <!-- Actions -->
        <div class="col-12 mt-5">
          <b-button
            @click="clickApplyWeighting()"
            variant="success"
            class="mr-2"
            ><i class="bx bx-save"></i>
            {{
              $t("datafileResultGeneral.weightingModal.applyButton")
            }}</b-button
          >
          <b-button @click="clickApplyReset()" variant="primary" class="mr-2"
            ><i class="bx bx-reset"></i>
            {{
              $t("datafileResultGeneral.weightingModal.resetButton")
            }}</b-button
          >
          <b-button @click="toggleModal()" variant="light"
            ><i class="bx bx-x"></i>
            {{
              $t("datafileResultGeneral.weightingModal.cancelButton")
            }}</b-button
          >
        </div>
      </div>

      <!-- Loading untile data get set --->
      <div class="row justify-content-center" v-else>
        <div class="col-6 text-center p-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
    </div>
  </b-modal>
</template>
